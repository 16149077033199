/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import globalWS from "../../globle";
import "./List.scss";

interface IProps {
  siteId: string;
  band: number;
  enable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intl: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ClickableChips(props: IProps) {
  const [checked, setChecked] = useState(props.enable);
  const handleClick = () => {
    setChecked(!checked);
    // start attack
    const ws = globalWS.getWS();
    const bandAttackMsg = {
      code: 101,
      data: {
        command_id: "111111",
        site_ids: [props.siteId],
        type: "wide_band_attack",
        wide_band_attack: {
          gain: 11,
          direction: 1.1,
          elevation: 1.111,
          bands: [{ band: props.band, enable: !checked }],
        },
      },
    };
    ws?.send(JSON.stringify(bandAttackMsg));
  };
  useEffect(() => {
    setChecked(props.enable);
  }, [props.enable]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div>
      <Checkbox className="anotherSwitch" onChange={handleClick} checked={checked} />
    </div>
  );
}
