/* eslint-disable @typescript-eslint/no-explicit-any */
interface CacheItem {
  value: any;
  expiry: number;
}

export class Cache {
  private cache: Record<string, CacheItem>;

  constructor() {
    this.cache = {};
  }

  public get(key: string): any {
    const cached = this.cache[key];
    if (!cached) return undefined;
    if (cached.expiry < Date.now()) {
      delete this.cache[key];
      return undefined;
    }
    return cached.value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public set(key: string, value: any, ttl = 1000 * 60 * 5): void {
    this.cache[key] = {
      value,
      expiry: Date.now() + ttl,
    };
  }
  public delete(key: string): void {
    delete this.cache[key];
  }
}
