/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Card, Tabs } from "antd";
import { AlignItemsList, CheckboxListSecondary } from "../../utils/mui/List";
import { Jammer } from "../../types";
import "./index.scss";
import moment from "moment";

interface Props {
  id: string;
  online: boolean;
  state: number;
  gps: [number, number];
  model?: string;
  isMaster?: boolean; //是否开启组网
  childTotal?: number;
  childCurCount?: number;
  bands: Jammer[];
  version: string;
  autoAtck?: boolean;
  enableDetected?: boolean;
  alias: string;
  intl: any;
  lastConnectTime: string;
  alertAllShow: any;
  alertContent: any;
  type: string;
}

const PopUpCompent: React.FC<Props> = props => {
  function truncateString(str: string, maxLength: number) {
    if (str.length <= maxLength) {
      return str;
    } else {
      const prefix = str.slice(0, 6);
      const suffix = str.slice(-6);
      return prefix + "..." + suffix;
    }
  }
  return (
    <Card
      className="popCarsClassName"
      title={
        <div className="popHeader">
          <div className="popHeaderName" title={props.alias === "" ? props.id : props.alias}>
            {props.intl.formatMessage({ id: "Mui.pop.SiteName" })}:{" "}
            <span>{truncateString(props.alias === "" ? props.id : props.alias, 14)}</span>
          </div>
          <div className="popHeaderTimeLabel">{props.intl.formatMessage({ id: "Mui.pop.loginTime" })}</div>
          <div className="popHeaderTimeValue">{moment(props.lastConnectTime).format("YYYY-MM-DD HH:mm:ss")}</div>
        </div>
      }
    >
      {localStorage.getItem("deviceBool") === "1" && props.type !== "HANDHELD" ? (
        <div className="cardTab">
          <Tabs
            defaultActiveKey="0"
            tabPosition="bottom"
            items={[
              {
                label: (
                  <div className="cardFirstAll">
                    <div className="cardFirstTab"></div>
                    <div className="cardFirstBottom"></div>
                  </div>
                ),
                key: "0",
                children: AlignItemsList({ ...props }),
              },
              {
                label: (
                  <div className="cardLastAll">
                    <div className="cardLastTab"></div>
                    <div className="cardLastBottom"></div>
                  </div>
                ),
                key: "1",
                children: CheckboxListSecondary(
                  props.id,
                  typeof props.bands == "string" ? JSON.parse(props.bands) : props.bands,
                  props.intl,
                  props.alertAllShow,
                  props.alertContent,
                ),
              },
            ]}
          />
          <div className="cardTabMiddle"></div>
        </div>
      ) : (
        <div className="cardTab">{AlignItemsList({ ...props })}</div>
      )}
    </Card>
  );
};

export default PopUpCompent;
