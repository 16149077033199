/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AppLocal from "../../lang";

export interface LocaleState {
  curLocal: any;
  Lan: any;
}

const initialState: LocaleState = {
  // curLocal:AppLocal["en_US"],
  curLocal:
    AppLocal[
      localStorage.getItem("Local")
        ? localStorage.getItem("Local") === "ru_RU "
          ? "ru_RU"
          : localStorage.getItem("Local") === "zh_CN"
            ? "zh_CN"
            : "en_US"
        : "en_US"
    ],
  Lan: localStorage.getItem("Local") ? localStorage.getItem("Local") : "en_US",
};

export const localeSlice = createSlice({
  name: "Locale",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeLocale: (state: LocaleState, action: PayloadAction<"zh_CN" | "en_US" | "ru_RU">) => {
      state.curLocal = AppLocal[action.payload];
      state.Lan = action.payload;
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export default localeSlice.reducer;
