/* eslint-disable @typescript-eslint/no-explicit-any */
// yandex lanlist
// lang=tr_TR
// lang=en_US (distance in miles)
// lang=en_RU
// lang=ru_RU
// lang=ru_UA
// lang=uk_UA

function getYandexLan(lan: string) {
  const yandexLanList = {
    "en-US": "en_US",
    "ru-RU": "ru_RU",
  };
  if ((yandexLanList as any)[lan]) {
    return (yandexLanList as any)[lan];
  } else {
    return "en_US";
  }
}

function getGoogleLan(lan: string) {
  const googleLanList = {
    "zh-Hans-CN": "zh-CN",
    "en-US": "en",
    "ru-RU": "ru",
  };
  if ((googleLanList as any)[lan]) {
    return (googleLanList as any)[lan];
  } else {
    return "en";
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformToMapLan(locale: string, mapType: string) {
  switch (mapType) {
    case "yandex":
      return getYandexLan(locale);
    case "google":
      return getGoogleLan(locale);
    default:
      return "en";
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformStyleJson(style: any, locale: string, maptype: string, sourceType: string) {
  let tiles;
  if (sourceType == "satellite" && style.sources && style.sources.satellite) {
    tiles = style.sources.satellite.tiles;
    const newTiles = tiles.map((tileUrl: string) => {
      return transformTileUrl(tileUrl, maptype, locale, sourceType);
    });
    return newTiles;
  }
  if (sourceType == "vector" && style.sources && style.sources.vector) {
    tiles = style.sources.vector.tiles;
    const newTiles = tiles.map((tileUrl: string) => {
      return transformTileUrl(tileUrl, maptype, locale, sourceType);
    });
    return newTiles;
  }

  if (sourceType == "annotion" && style.sources && style.sources.annotion) {
    tiles = style.sources.annotion.tiles;
    const newTiles = tiles.map((tileUrl: string) => {
      return transformTileUrl(tileUrl, maptype, locale, sourceType);
    });
    return newTiles;
  }
  return;
}

function transformTileUrl(tileUrl: string, mapType: string, locale: string, sourceType: string) {
  if (mapType == "yandex") {
    const part = tileUrl.split("/");
    part[part.length - 1] = transformToMapLan(locale, mapType);
    const modifiedUrl = part.join("/");
    return modifiedUrl;
  }
  if (mapType == "google") {
    if (sourceType == "satellite") {
      const reg = /(?<=hl=).*?(?=&)/;
      return tileUrl.replace(reg, transformToMapLan(locale, mapType));
      // return `https://mt2.google.com/vt?lyrs=s&v=883&hl=${transformToMapLan(locale,mapType)}&gl=CN&x={x}&y={y}&z={z}`
    } else if (sourceType == "annotion") {
      const reg = /(?<=!3m17!2s).*?(?=!3s)/;
      const reg2 = /(?<=!3s).*?(?=!5e18)/;
      return tileUrl.replace(reg, transformToMapLan(locale, mapType)).replace(reg2, transformToMapLan(locale, mapType));
      // return `https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i535257774!3m17!2s${transformToMapLan(locale,mapType)}!3s${transformToMapLan(locale,mapType)}!5e18!12m4!1e68!2m2!1sset!2sRoadmapSatellite!12m3!1e37!2m1!1ssmartmaps!12m4!1e26!2m2!1sstyles!2zcy50OjJ8cy5lOmx8cC52Om9mZg!4e0!23i1379896!23i1379903!23i1376099&key=AIzaSyCeCzXVJo5AS-j4-K_tL-FPkMwjruQkzP4`
    } else if (sourceType == "vector") {
      const reg = /(?<=!3m17!2s).*?(?=!3s)/;
      const reg2 = /(?<=!3s).*?(?=!5e18)/;
      return tileUrl.replace(reg, transformToMapLan(locale, mapType)).replace(reg2, transformToMapLan(locale, mapType));
    }
  }
}
