/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
  DataGrid,
  zhCN,
  enUS,
  ruRU,
  GridColDef,
  useGridApiRef,
  GridToolbarQuickFilter,
  GridCellEditStopParams,
  MuiEvent,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import { message } from "antd";
import "./common.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import request from "../../api/request";
import { Box } from "@mui/material";
import { updateSiteInfo } from "../../redux//objectList/siteInfo";
import { SiteInfo } from "../../types";
import { getToken } from "../util";

interface IProps {
  rows: any[];
  columns: GridColDef[];
  getSelectIds: any;
  intl: any;
  sites: SiteInfo[];
}
interface reqResponse {
  code: number;
  msg: string;
}

const VISIBLE_FIELDS = [
  "alias",
  "status",
  "version",
  "siteId",
  "connectTime",
  "operation",
  "coord",
  "siteType",
  "desc",
];
function QuickSearchToolbar() {
  return (
    <Box className="siteListSearch">
      <GridToolbarQuickFilter />
    </Box>
  );
}

export default function DataTable(props: IProps) {
  const curLocale = useSelector((state: RootState) => state.localReducer.Lan);
  const gridApiRef = useGridApiRef();
  const dispatch = useDispatch();
  function customSort(rows: any) {
    const order = [
      props.intl.formatMessage({ id: "homePage.siteOverview.online" }),
      props.intl.formatMessage({ id: "homePage.siteOverview.fault" }),
      props.intl.formatMessage({ id: "homePage.siteOverview.offline" }),
    ];
    return [...rows].sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status));
  }
  const columns = React.useMemo(
    () =>
      props.columns
        .filter(column => VISIBLE_FIELDS.includes(column.field))
        .map(column => {
          if (column.field === "alias") {
            return {
              ...column,
              filterable: true,
            };
          }
          return column;
        }),
    [props.columns],
  );
  const getRowClassName = (params: { row: { status: string } }) => {
    if (params.row.status === props.intl.formatMessage({ id: "homePage.siteOverview.offline" })) {
      return "offline-row"; // CSS class for offline rows
    }
    return ""; // No special class for other rows
  };

  //修改别名
  const processRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.alias !== oldRow.alias) {
      const hasCommonAlias = props.rows.some((item: { alias: string }) => {
        return item.alias === newRow.alias;
      });
      const token: string | null = getToken();
      if (token === null) {
        return;
      }
      if (newRow.alias === "") {
        newRow.alias = newRow.siteId;
      }
      const data = new FormData();
      data.append("siteId", newRow.siteId);
      data.append("alias", newRow.alias);
      const header: any = {
        "Content-Type": "multipart/form-data",
        token: token,
      };
      if (hasCommonAlias) {
        message.warning(`${props.intl.formatMessage({ id: "SiteList.renderCell.rnameUsed" })}`);
        return oldRow;
      } else {
        request("post", "alias", data, header).then(res => {
          const rep: reqResponse = res.data as reqResponse;
          if (rep.code === 1) {
            props.sites.forEach(item => {
              if (item.site_id === newRow.siteId) {
                dispatch(
                  updateSiteInfo({
                    site_id: newRow.siteId,
                    alias: newRow.alias,
                    health: false,
                    coordinate: item.coordinate,
                    online: item.online,
                    proxyStatus: 0,
                    timestamp: 0,
                    current_sensor_count: item.current_sensor_count,
                    model: item.model,
                    state: item.state,
                    version: item.version,
                    total_sensor_count: item.total_sensor_count,
                    jammerState: item.jammerState,
                    enable_auto_attack: item.enable_auto_attack,
                    enable_detect: item.enable_detect,
                    last_connect_time: item.last_connect_time,
                    last_report_time: item.last_report_time,
                    type: item.type,
                    range: item.range,
                    camStatus: item.camStatus,
                    desc: item.desc,
                    airDefenceArea: item.airDefenceArea,
                  }),
                );
              }
            });
            gridApiRef.current.stopCellEditMode({ id: newRow.siteId, field: "alias", ignoreModifications: true });
            message.success(`${props.intl.formatMessage({ id: "SiteList.renderCell.rnameSucess" })}`);
          } else if (rep.code === -120) {
            message.error(`${props.intl.formatMessage({ id: "account.userList.alloperationAuth" })}`);
            return oldRow;
          } else {
            message.error(`${props.intl.formatMessage({ id: "SiteList.renderCell.rnameFail" })}`);
            return oldRow;
          }
        });
      }
    }
    if (newRow.desc !== oldRow.desc) {
      const token: string | null = getToken();
      if (token === null || newRow.alias === "") {
        return;
      }
      const data = new FormData();
      data.append("siteId", newRow.siteId);
      data.append("desc", newRow.desc);
      const header: any = {
        "Content-Type": "multipart/form-data",
        token: token,
      };
      request("post", "site-desc", data, header).then(res => {
        const rep: reqResponse = res.data as reqResponse;
        if (rep.code === 0) {
          props.sites.forEach(item => {
            if (item.site_id === newRow.siteId) {
              dispatch(
                updateSiteInfo({
                  site_id: newRow.siteId,
                  alias: item.alias,
                  health: false,
                  coordinate: item.coordinate,
                  online: item.online,
                  proxyStatus: 0,
                  timestamp: 0,
                  current_sensor_count: item.current_sensor_count,
                  model: item.model,
                  state: item.state,
                  version: item.version,
                  total_sensor_count: item.total_sensor_count,
                  jammerState: item.jammerState,
                  enable_auto_attack: item.enable_auto_attack,
                  enable_detect: item.enable_detect,
                  last_connect_time: item.last_connect_time,
                  last_report_time: item.last_report_time,
                  type: item.type,
                  range: item.range,
                  camStatus: item.camStatus,
                  desc: newRow.desc,
                  airDefenceArea: newRow.airDefenceArea,
                }),
              );
            }
          });
          gridApiRef.current.stopCellEditMode({ id: newRow.siteId, field: "desc", ignoreModifications: true });
          message.success(`${props.intl.formatMessage({ id: "SiteList.renderCell.rdescSucess" })}`);
        } else if (rep.code === -120) {
          message.error(`${props.intl.formatMessage({ id: "account.userList.alloperationAuth" })}`);
          return oldRow;
        } else {
          message.error(`${props.intl.formatMessage({ id: "SiteList.renderCell.rdescFail" })}`);
          return oldRow;
        }
      });
    }
  };

  return (
    <div style={{ height: "auto", width: "100%" }} className="siteTable">
      <DataGrid
        getRowClassName={getRowClassName}
        rows={customSort(props.rows)}
        columns={columns}
        autoHeight={true}
        apiRef={gridApiRef}
        checkboxSelection
        disableRowSelectionOnClick
        //搜索
        slots={{ toolbar: QuickSearchToolbar }}
        hideFooter={true}
        //这个属性是去掉在表头的筛选
        disableColumnMenu
        processRowUpdate={processRowUpdate}
        onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            // 检查单元格编辑是否因点击单元格外部而停止
            if (!event.defaultMuiPrevented) {
              // 将值还原为原始值
              gridApiRef.current.stopCellEditMode({
                id: params.id,
                field: params.field,
                ignoreModifications: true,
              });
            }
          }
        }}
        localeText={
          curLocale === "zh_CN"
            ? zhCN.components.MuiDataGrid.defaultProps.localeText
            : curLocale === "en_US"
              ? enUS.components.MuiDataGrid.defaultProps.localeText
              : ruRU.components.MuiDataGrid.defaultProps.localeText
        }
        onRowSelectionModelChange={Ids => {
          const selectIds: any[] = [];
          const ids = new Set(Ids);
          const selectRow = props.rows.filter(row => ids.has(row.id));
          if (selectRow.length > 0) {
            selectRow.forEach(row => {
              selectIds.push(row.siteId);
            });
          }
          props.getSelectIds(selectIds);
        }}
      />
    </div>
  );
}
