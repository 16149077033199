/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "./index.scss";
import React from "react";
import request from "../../api/request";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataTable from "../../utils/mui/siteTable";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import BasicModal from "../../utils/mui/modal";
import {
  OnlineCell,
  OpCellCompent,
  StatusCell,
  PositionCell,
  SiteTypeCell,
  AliasCell,
  DescCell,
} from "../../utils/mui/renderCell";
import mapboxgl from "mapbox-gl";
import { SiteInfo } from "../../types";
import { message } from "antd";
import { removeSiteByIds } from "../../redux/objectList/siteInfo";
import * as Redux from "redux";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { getToken } from "../../utils/util";
import { Tooltip } from "@mui/material";

interface Coord {
  latitude: number;
  longitude: number;
  altitude?: number;
  height?: number;
}

interface Jammer {
  band: number;
  state: number;
}
interface propsData {
  site_id: string;
  alias: string;
  health: boolean;
  coordinate: Coord;
  online: boolean;
  proxyStatus: number;
  timestamp: number;
  current_sensor_count: number;
  model: string;
  state: number;
  version: string;
  total_sensor_count: number;
  jammerState: Jammer[];
  last_connect_time: string;
  last_report_time: string;
  type: string;
  desc: string;
}

interface IProps {
  data: {
    site_id: string;
    alias: string;
    health: boolean;
    coordinate: Coord;
    online: boolean;
    proxyStatus: number;
    timestamp: number;
    current_sensor_count: number;
    model: string;
    state: number;
    version: string;
    total_sensor_count: number;
    jammerState: Jammer[];
    last_connect_time: string;
    last_report_time: string;
    type: string;
    desc: string;
  }[];
  proxyServer: string;
  isVisible: boolean;
  handleVisibilityChange: (widget: string, show: boolean) => void;
  globalmMap?: mapboxgl.Map;
  intl?: any;
  handleModal: any;
  wideBandChange: boolean;
  autoChange: boolean;
  sites: SiteInfo[];
  removeSitesByIds: (payload: string[]) => void;
  curSiteInfos: SiteInfo[];
}

interface IState {
  modifyId: string;
  aliasText: string;
  tip: tipInfo;
  showModal: boolean;
  modalType: string;
  modalTitle: string;
  selectSiteIds: string[];
  deleteShow: boolean;
}

interface tipInfo {
  isShow: boolean;
  content: string;
  tipType: string;
}

interface reqResponse {
  code: number;
  msg: string;
}

class SiteList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modifyId: "",
      aliasText: "",
      tip: {
        isShow: false,
        content: "",
        tipType: "info",
      },
      showModal: false,
      modalType: "",
      modalTitle: "",
      selectSiteIds: [],
      deleteShow: false,
    };
  }

  private getButtons = (onClick: any, props: any) => {
    return [
      <Tooltip title={props.intl.formatMessage({ id: "SiteList.delTitle" })} arrow>
        <span>
          <Button
            className="siteListLastBtn"
            onClick={() => {
              this.deleteUnLineSite();
            }}
            style={{ display: this.state.deleteShow ? "block" : "none" }}
          >
            <div className="delIcon"></div>
          </Button>
        </span>
      </Tooltip>,
      <Tooltip title={props.intl.formatMessage({ id: "SiteList.Broadband.interference" })} arrow>
        <span>
          <Button className="siteListFirstBtn" key="one" id="wideBtn" onClick={e => onClick(e)}>
            <div className="BroadbandIcon" id="wideBtn"></div>
          </Button>
        </span>
      </Tooltip>,
      <Tooltip title={props.intl.formatMessage({ id: "SiteList.unattended" })} arrow>
        <span>
          <Button className="siteListSecondBtn" key="four" id="autoAtk" onClick={e => onClick(e)}>
            <div className="unattendedIcon" id="autoAtk"></div>
          </Button>
        </span>
      </Tooltip>,
    ];
  };

  private renderCell = (
    type: string,
    params: GridRenderCellParams,
    map: mapboxgl.Map,
    handleModal: any,
    intl: any,
    data: any,
  ) => {
    switch (type) {
      case "op":
        return (
          <OpCellCompent param={params} map={map} handleModal={handleModal} intl={intl} data={data}></OpCellCompent>
        );
      case "alias":
        return <AliasCell param={params.value}></AliasCell>;
      case "desc":
        return <DescCell param={params.value}></DescCell>;
      case "online":
        return <OnlineCell isOnline={params.value}></OnlineCell>;
      case "position":
        return <PositionCell param={params} map={map} handleModal={handleModal} intl={intl} data={data}></PositionCell>;
      case "status":
        return <StatusCell isFault={params.value} intl={intl}></StatusCell>;
      case "siteType":
        return <SiteTypeCell isType={params.value} intl={intl}></SiteTypeCell>;
    }
  };

  private GroupSizesColors(handleClick: any, props: any) {
    return (
      <div className="siteListBigBox">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup color="secondary" aria-label="medium secondary button group">
            {this.getButtons(handleClick, props)}
          </ButtonGroup>
        </Box>
      </div>
    );
  }

  private getColumn = (props: any) => {
    const columns: GridColDef[] = [
      {
        field: "alias",
        headerName: `${props.intl.formatMessage({ id: "SiteList.SiteName" })}`,
        headerClassName: "aliasHeaderName",
        cellClassName: "aliasName",
        headerAlign: "left",
        align: "left",
        sortable: false,
        editable: localStorage.getItem("systenBool") === "1" ? true : false,
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "alias",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
      },
      {
        field: "status",
        headerName: `${props.intl.formatMessage({ id: "SiteList.Status" })}`,
        headerClassName: "statusHeaderName",
        cellClassName: "statusName",
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "status",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
      },
      {
        field: "siteType",
        headerName: `${props.intl.formatMessage({ id: "homePage.eventsList.column.siteType" })}`,
        headerClassName: "siteTypeHeaderName",
        cellClassName: "siteTypeName",
        headerAlign: "left",
        align: "left",
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "siteType",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
      },
      (localStorage.getItem("deviceBool") === "1" && {
        field: "operation",
        headerName: `${props.intl.formatMessage({ id: "SiteList.Operation" })}`,
        sortable: false,
        headerClassName: "operationHeaderName",
        cellClassName: "operationName",
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "op",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
      }) as GridColDef,
      {
        field: "coord",
        headerName: `${props.intl.formatMessage({ id: "SiteList.Coordinate" })}`,
        headerClassName: "coordHeaderName",
        cellClassName: "coordName",
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "position",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
        sortable: false,
      },
      {
        field: "desc",
        headerName: `${props.intl.formatMessage({ id: "SiteList.Desc" })}`,
        headerClassName: "descHeaderName",
        cellClassName: "descName",
        headerAlign: "left",
        align: "left",
        sortable: false,
        editable: localStorage.getItem("systenBool") === "1" ? true : false,
        renderCell: (params: GridRenderCellParams) =>
          this.renderCell(
            "desc",
            params,
            this.props.globalmMap as mapboxgl.Map,
            this.props.handleModal,
            this.props.intl,
            this.props.data,
          ),
      },
    ];
    return columns;
  };

  private getSelectIds = (ids: any) => {
    this.setState({ selectSiteIds: ids });
  };

  // 0 => close | 1 => open | 2 => offline
  private handleProxy = (id: string, status: number) => {
    const token: string | null = getToken();
    if (token == null || id === "") {
      console.warn("token/id is null");
      return;
    }
    const header: any = {
      "Content-Type": "multipart/form-data",
      token: token,
    };
    const data = new FormData();
    data.append("siteId", id);
    switch (status) {
      case 0:
        data.append("option", "open");
        request("post", "proxy", data, header).then(res => {
          const rep: reqResponse = res.data as reqResponse;
          if (rep.code === 1) {
            this.setState({
              tip: {
                isShow: true,
                content: "打开代理成功",
                tipType: "info",
              },
            });
          } else {
            this.setState({
              tip: {
                isShow: true,
                content: "打开代理失败",
                tipType: "error",
              },
            });
          }
        });
        return;
      case 1:
        data.append("option", "close");
        request("post", "proxy", data, header).then(res => {
          const rep: reqResponse = res.data as reqResponse;
          if (rep.code === 1) {
            this.setState({
              tip: {
                isShow: true,
                content: "关闭代理成功",
                tipType: "info",
              },
            });
          } else {
            this.setState({
              tip: {
                isShow: true,
                content: "关闭代理失败",
                tipType: "error",
              },
            });
          }
        });
        return;
      case 2:
        console.warn("option not support");
        return;
    }
  };
  private tip = (msg: string, tipType: string): JSX.Element => {
    setTimeout(() => {
      this.setState({
        tip: {
          isShow: false,
          content: "",
          tipType: "info",
        },
      });
    }, 2500);
    return (
      <div
        className="animate__animated animate__bounceIn sitelist-notify-tip"
        style={{ backgroundColor: tipType === "info" ? "#0000005c" : "#f76e58a3" }}
      >
        {msg}
      </div>
    );
  };

  private handCloseCallbak = () => {
    this.setState({
      showModal: false,
    });
  };

  private handleClick = (e: any, props: any) => {
    if (this.state.selectSiteIds.length === 0)
      return message.error(`${props.intl.formatMessage({ id: "SiteList.modal.selectSite" })}`);
    switch (e.target.id) {
      case "wideBtn":
        this.setState({
          showModal: true,
          modalTitle: `${props.intl.formatMessage({ id: "SiteList.Broadband.interference" })}`,
          modalType: "0",
        });
        break;
      case "autoAtk":
        this.setState({
          showModal: true,
          modalTitle: `${props.intl.formatMessage({ id: "SiteList.unattended" })}`,
          modalType: "2",
        });
        break;
      default:
    }
  };

  private findCommonElements(arr1: string[], arr2: propsData[]) {
    const set1 = new Set(arr1);
    const commonElements = arr2.filter((element: { site_id: string }) => set1.has(element.site_id));
    return commonElements;
  }
  componentDidUpdate(): void {
    if (this.findCommonElements(this.state.selectSiteIds, this.props.data).length > 0) {
      const showDelte = this.findCommonElements(this.state.selectSiteIds, this.props.data).every(
        obj => obj.online === false,
      );
      if (showDelte !== this.state.deleteShow) {
        this.setState({
          deleteShow: showDelte,
        });
      }
    } else {
      if (this.state.deleteShow) {
        this.setState({
          deleteShow: false,
        });
      }
    }
  }

  private deleteUnLineSite = () => {
    const token: string | null = getToken();
    if (token == null) {
      console.warn("token is null");
      return;
    }
    const header: any = {
      "Content-Type": "multipart/form-data",
      token: token,
    };
    const data = new FormData();
    this.state.selectSiteIds.forEach((item: string) => {
      data.append("site_id", item);
    });
    request("post", "delete-site", data, header).then(res => {
      const rep: reqResponse = res.data as reqResponse;
      if (rep.code === 0) {
        message.success(this.props.intl.formatMessage({ id: "SiteList.delSucess" }));
        this.props.removeSitesByIds(this.state.selectSiteIds);
        this.setState({
          deleteShow: false,
        });
      } else {
        message.success(this.props.intl.formatMessage({ id: "SiteList.delFail" }));
        this.setState({
          deleteShow: true,
        });
      }
    });
    return;
  };

  render() {
    const rows = [];
    type row = {
      id: string;
      siteId: string;
      alias: string;
      connectTime: string;
      isOnline: boolean;
      operation: string;
      coord: Coord;
      status: string;
      version: string;
      siteType: string;
      desc: string;
    };
    for (const v of this.props.data) {
      const row: row = {
        id: v.site_id,
        siteId: v.site_id,
        alias: v.alias ? v.alias : v.site_id,
        connectTime: v.last_connect_time,
        isOnline: v.online,
        operation: "op",
        coord: v.coordinate,
        version: v.version,
        status: v.online
          ? v.state === 2
            ? `${this.props.intl.formatMessage({ id: "homePage.siteOverview.fault" })}`
            : `${this.props.intl.formatMessage({ id: "homePage.siteOverview.online" })}`
          : `${this.props.intl.formatMessage({ id: "homePage.siteOverview.offline" })}`,
        siteType: v.type ? v.type : "",
        desc: v.desc,
      };
      rows.push(row);
    }
    return (
      <div className="sitelist">
        <div className="sitelist-header">
          {this.state.tip.isShow && this.tip(this.state.tip.content, this.state.tip.tipType)}
        </div>
        <DataTable
          rows={rows}
          columns={this.getColumn(this.props)}
          getSelectIds={this.getSelectIds}
          intl={this.props.intl}
          sites={this.props.sites}
        ></DataTable>
        {localStorage.getItem("deviceBool") === "1" &&
          this.GroupSizesColors((e: Event) => this.handleClick(e, this.props), this.props)}
        <div>
          {this.state.showModal && (
            <BasicModal
              intl={this.props.intl}
              wideBandChange={this.props.wideBandChange}
              autoChange={this.props.autoChange}
              data={this.props.data}
              open={this.state.showModal}
              siteIds={this.state.selectSiteIds}
              type={this.state.modalType}
              title={this.state.modalTitle}
              onClose={this.handCloseCallbak}
            ></BasicModal>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  curSiteInfos: state.curSiteReducer.curSiteInfo, // 确保与您的Redux状态结构匹配
});

const mapDispatchToProps = (dispatch: Redux.Dispatch) => ({
  removeSitesByIds(payload: string[]) {
    dispatch(removeSiteByIds(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
