/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";
import globalWS, { globalMap } from "../../globle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { DroneInfo, SiteInfo } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { setCurImage, setImageLoading } from "../../redux/video/video";
import { RootState } from "../../redux/store";
import { Cache } from "../../utils/cache";
import { findValueBySiteId } from "../../utils/util";

interface Props {
  drone: DroneInfo;
  intl: any;
  sites: SiteInfo[];
  siteCache: Cache;
  droneSmallMarkerList: mapboxgl.Marker[];
  allSites: string[];
}

const DroneItem: FC<Props> = ({ drone, droneSmallMarkerList, sites, siteCache, intl, allSites }) => {
  // Component logic here
  const [anchorEl, setAncholEl] = useState<null | (EventTarget & Element)>(null);
  const [imageSrc, setImageSrc] = useState(drone.image_url);

  const curImage = useSelector((state: RootState) => {
    return state.videoReducer.curImage;
  });
  const dispatch = useDispatch(); // 获取 dispatch 函数
  //点击无人机然后地图上对应的图标高亮且定位
  const handleClickDroneItem = () => {
    if (drone.coordinate?.latitude === 0 && drone.coordinate?.longitude === 0) {
      return;
    } else {
      const centerPoint = { lat: drone.coordinate?.latitude as number, lng: drone.coordinate?.longitude as number };
      globalMap.getMap()?.setZoom(16);
      globalMap.getMap()?.setCenter(centerPoint);
    }
  };

  const onMouseEnter = (e: React.MouseEvent) => {
    findValueBySiteId(sites, allSites[0]);
    if (siteCache.get(allSites[0])?.type === "CAMERA") {
      setAncholEl(e.currentTarget);
      //请求当前图片、
      const ws = globalWS.getWS();
      const data = {
        code: 107,
        data: {
          site_id: allSites[0],
          drone_id: drone.drone_id,
        },
      };
      ws?.send(JSON.stringify(data));
      dispatch(setImageLoading(true));
    } else {
      setAncholEl(null);
      dispatch(setCurImage(""));
      dispatch(setImageLoading(false));
    }
  };

  const onMouseLeave = () => {
    if (siteCache.get(allSites[0])?.type === "CAMERA") {
      setAncholEl(null);
      dispatch(setCurImage(""));
      dispatch(setImageLoading(false));
    }
  };
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent, siteId: any, droneId: string, sensorId?: string) => {
    e.stopPropagation();
    const ws = globalWS.getWS();
    let data;
    switch ((e.currentTarget as any)?.id) {
      case "precisonAtk":
        //attack precsion for drone
        if (drone.attacking) {
          data = {
            code: 101,
            data: {
              command_id: "111111",
              site_ids: siteId,
              type: "attack",
              attack: {
                enable: false,
                target_id: droneId,
                takeover: false,
              },
            },
          };
          ws?.send(JSON.stringify(data));
        } else {
          data = {
            code: 101,
            data: {
              command_id: "111111",
              site_ids: siteId,
              type: "attack",
              attack: {
                enable: true,
                target_id: droneId,
                takeover: false,
              },
            },
          };
          ws?.send(JSON.stringify(data));
        }
        break;
      case "addWhitelist":
        if (drone.whitelisted) {
          siteId.forEach((item: string) => {
            data = {
              code: 115,
              data: {
                command_id: "11111111",
                site_id: item,
                drone_id: droneId,
                drone_type: drone.drone_type,
                time_range: "permanent,permanent",
                operation: -1,
              },
            };
            ws?.send(JSON.stringify(data));
          });
        } else {
          siteId.forEach((item: string) => {
            data = {
              code: 115,
              data: {
                command_id: "11111111",
                site_id: item,
                drone_id: droneId,
                drone_type: drone.drone_type,
                time_range: "permanent,permanent",
                operation: 1,
              },
            };
            ws?.send(JSON.stringify(data));
          });
        }

        break;
      case "tracking":
        if (
          siteCache.get(allSites[0])?.camStatus &&
          siteCache.get(allSites[0])?.camStatus[0]?.work_mode === "TRACK" &&
          siteCache.get(allSites[0])?.camStatus[0]?.tracking_drone_id === droneId
        ) {
          data = {
            code: 109,
            data: {
              site_id: siteId,
              sensor_id: sensorId,
              drone_id: "",
            },
          };
          ws?.send(JSON.stringify(data));
        } else {
          data = {
            code: 109,
            data: {
              site_id: siteId,
              sensor_id: sensorId,
              drone_id: droneId,
            },
          };
          ws?.send(JSON.stringify(data));
        }
        break;
      case "falseAlert":
        data = {
          code: 111, // removeFakeDrone
          data: {
            site_id: siteId,
            drone_id: droneId,
          },
        };
        ws?.send(JSON.stringify(data));
        break;
    }
  };

  const directions = [
    `${intl.formatMessage({ id: "droneList.item.north" })}`,
    `${intl.formatMessage({ id: "droneList.item.northeast" })}`,
    `${intl.formatMessage({ id: "droneList.item.east" })}`,
    `${intl.formatMessage({ id: "droneList.item.southeast" })}`,
    `${intl.formatMessage({ id: "droneList.item.south" })}`,
    `${intl.formatMessage({ id: "droneList.item.southwest" })}`,
    `${intl.formatMessage({ id: "droneList.item.west" })}`,
    `${intl.formatMessage({ id: "droneList.item.northwest" })}`,
  ];

  //图片加载失败
  function handleImageError() {
    // 在这里可以执行加载失败后的操作，比如显示默认图片或者给用户提示
    setImageSrc("dimages/uav_spectrum.png");
  }
  //图片加载成功
  function handleImageLoad() {
    // console.log("object2",imageSrc);
  }

  function truncateString(str: string, maxlength: number) {
    if (str && str.length > maxlength) {
      const start = str.slice(0, 9);
      const end = str.slice(-9);
      return start + "..." + end;
    }
    return str;
  }

  const itemMouseEnter = () => {
    for (const marker of droneSmallMarkerList) {
      const markerId = marker.getElement()?.getAttribute("data-marker-id");
      const targetElement = marker.getElement()?.childNodes[1].childNodes[1] as HTMLElement;
      if (markerId === drone.drone_id) {
        targetElement.className = "point";
      }
    }
  };

  const itemMouseLeave = () => {
    for (const marker of droneSmallMarkerList) {
      const markerId = marker.getElement()?.getAttribute("data-marker-id");
      const targetElement = marker.getElement()?.childNodes[1].childNodes[1] as HTMLElement;
      if (markerId === drone.drone_id) {
        targetElement.className = "Nopoint";
      }
    }
  };

  const siteType = allSites === null ? "BASE" : siteCache.get(allSites[0])?.type;

  const iconPath =
    siteType === "BASE"
      ? "/icon/miniBase.png"
      : siteType === "CAMERA"
        ? "/icon/miniCamera.png"
        : siteType === "HANDHELD"
          ? "/icon/miniHandHeld.png"
          : "/icon/miniBase.png";
  return (
    <div
      className="droneListItem"
      onClick={() => {
        handleClickDroneItem();
      }}
      onMouseEnter={itemMouseEnter}
      onMouseLeave={itemMouseLeave}
    >
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id={`panel1a-header${drone.drone_id}`}
          sx={{
            minHeight: 0,
            padding: 0,
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <div className="droneListItemMarker">
            <img
              className={
                siteType === "CAMERA"
                  ? "droneListItemMarker_img_camera"
                  : siteType === "HANDHELD"
                    ? "droneListItemMarker_img_handHeld"
                    : "droneListItemMarker_img_base"
              }
              src={iconPath}
              alt="Icon"
            />
          </div>

          <div className="droneListItemInfo">
            <div className="droneListRow">
              <div className="listFirstTitle label">{intl.formatMessage({ id: "homePage.drones.type" })}：</div>
              <div className="listFirstContent value">
                <Tooltip title={drone.name} arrow>
                  <span>{drone?.name} </span>
                </Tooltip>
              </div>
            </div>
            <div className="droneListRow">
              <div className="listFirstTitle label">ID：</div>
              <div className="listFirstContent value">
                <Tooltip title={drone.drone_id} arrow>
                  <span>{drone.drone_id}</span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="droneListItemPic" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <img
              alt="img"
              src={"./drone_pic" + imageSrc?.substr(7)}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
          {localStorage.getItem("deviceBool") === "1" && (
            <>
              {siteCache.get(allSites[0])?.type === "CAMERA" ? (
                <div className="droneListIconButton">
                  <div
                    className="droneListIconTop"
                    title={intl.formatMessage({ id: "droneList.operation.falseAlert" })}
                    onClick={e => {
                      handleClick(e, allSites[0], drone.drone_id);
                    }}
                    id="falseAlert"
                  >
                    <div className="droneListfalseAlert"></div>
                  </div>
                  <div
                    className="droneListIconBottom"
                    title={
                      siteCache.get(allSites[0])?.camStatus &&
                      siteCache.get(allSites[0])?.camStatus[0]?.work_mode === "TRACK"
                        ? intl.formatMessage({ id: "droneList.operation.trackingOff" })
                        : intl.formatMessage({ id: "droneList.operation.tracking" })
                    }
                    onClick={e => {
                      handleClick(e, allSites[0], drone.drone_id, "1");
                    }}
                    id="tracking"
                  >
                    <div
                      className={
                        siteCache.get(allSites[0])?.camStatus &&
                        siteCache.get(allSites[0])?.camStatus[0]?.work_mode === "TRACK"
                          ? "droneListAttacking"
                          : "droneListAttackingOff"
                      }
                    ></div>
                  </div>
                </div>
              ) : (
                <div className="droneListIconButton">
                  <div
                    className="droneListIconTop"
                    title={
                      drone.whitelisted
                        ? intl.formatMessage({ id: "droneList.operation.removeWhiteList" })
                        : intl.formatMessage({ id: "droneList.operation.addWhiteList" })
                    }
                    onClick={e => {
                      handleClick(e, allSites, drone.drone_id);
                    }}
                    id="addWhitelist"
                  >
                    <div className={drone.whitelisted ? "droneListRemoveWhite" : "droneListAddWhite"}></div>
                  </div>
                  {drone.can_attack && (
                    <div
                      className="droneListIconBottom"
                      title={
                        drone.attacking
                          ? intl.formatMessage({ id: "droneList.operation.attackingOff" })
                          : intl.formatMessage({ id: "droneList.operation.attacking" })
                      }
                      onClick={e => {
                        handleClick(e, allSites, drone.drone_id);
                      }}
                      id="precisonAtk"
                    >
                      <div className={drone.attacking ? "droneListAttacking" : "droneListAttackingOff"}></div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}
            className="droneListItemDetailsBox"
          >
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "Map.Coordinate" })}</div>
              <div className="value">
                {drone.coordinate?.longitude === 0 && drone.coordinate?.latitude === 0
                  ? `${intl.formatMessage({ id: "droneList.item.noCoordinate" })}`
                  : `${String(drone.coordinate?.longitude).length > 7 ? drone.coordinate?.longitude.toFixed(7) : drone.coordinate?.longitude},${String(drone.coordinate?.latitude).length > 7 ? drone.coordinate?.latitude.toFixed(7) : drone.coordinate?.latitude}`}
              </div>
            </div>
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "droneList.item.direction" })}</div>
              <div className="value">
                {drone.direction > 360
                  ? `${intl.formatMessage({ id: "droneList.item.noDirection" })}`
                  : `${Number(drone.direction).toFixed(1)}${String.fromCharCode(176)}(${directions[Math.round(drone.direction / 45) % 8]})`}
              </div>
            </div>
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "Map.Distance" })}</div>
              <div className="value">{Math.floor(drone.distance) + " m"}</div>
            </div>
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "Map.Speed" })}</div>
              <div className="value">{drone.speed.toFixed(2) + "  m/s"}</div>
            </div>
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "droneList.item.height" })}</div>
              <div className="value">{drone.coordinate?.height + " m"}</div>
            </div>
            <div className="droneListItemDetails">
              <div className="label">{intl.formatMessage({ id: "homePage.eventsList.column.siteName" })}</div>
              <div className="value">
                {allSites.map((site, index) => (
                  <Tooltip
                    title={siteCache.get(site)?.alias ? siteCache.get(site)?.alias : siteCache.get(site)?.site_id}
                    arrow
                    key={drone.name + index}
                  >
                    <div key={index}>
                      {siteCache.get(site)?.alias
                        ? truncateString(siteCache.get(site)?.alias, 15)
                        : truncateString(site, 15)}
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>
      {
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          disableRestoreFocus
        >
          {curImage ? (
            <Box className="curImageBox">
              <img style={{ objectFit: "contain" }} src={"data:image/png;base64," + curImage} alt="" />
            </Box>
          ) : (
            <LoadingComponent></LoadingComponent>
          )}
        </Popover>
      }
    </div>
  );
};

export default DroneItem;

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "8vh",
      }}
    >
      <CircularProgress size={40} />
      <Typography variant="caption">Loading...</Typography>
    </div>
  );
};
