/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SiteInfo } from "../types";

export function findValueBySiteId<K extends keyof SiteInfo>(array: SiteInfo[], targetId: string, _propertyKey?: K) {
  const targetItem = array.find(item => item.site_id === targetId);
  if (targetItem) {
    return targetItem;
  }
  return null; // 或其他适当的默认值，表示未找到
}

interface Location {
  latitude: number;
  longitude: number;
}

export const pointToGeoLocation = (origin: Location, points: number[]): Location => {
  const kRe = 6378137.0; // equator radius
  const kRp = 6356725.0; // polar radius

  const R: number = kRp + ((kRe - kRp) * Math.abs(90.0 - origin.latitude)) / 90.0;
  const Rlat: number = R * Math.cos((Math.PI * origin.latitude) / 180);

  return {
    latitude: origin.latitude + ((points[1] / R) * 180) / Math.PI,
    longitude: origin.longitude + ((points[0] / Rlat) * 180) / Math.PI,
  };
};

export const pointToTime = (defaultTime: string, addTime: number): Date => {
  return new Date(Date.parse(defaultTime) + addTime * 1000);
};

// export {pointToGeoLocation}

class ColorScheme {
  private primaryColor: string[] = [
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#FFA500",
    "#800080",
    "#4B0082",
    "#8B4513",
    "#FF6600",
    "#008000",
    "#8A2BE2",
    "#FFD700",
    "#00FF7F",
    "#FFD700",
    "#9400D3",
    "#A0522D",
    "#800080",
    "#D2691E",
  ];
  private selectedColor = "#f7a1ed";
  private idToColorMap: Map<string, string> = new Map<string, string>();

  getColorById(id: string, isSelected: boolean): string {
    if (isSelected) {
      return this.selectedColor;
    } else {
      if (!this.idToColorMap.has(id)) {
        const unusedColors = this.primaryColor.filter(color => {
          for (const v in this.idToColorMap.values()) {
            if (v === color) {
              return false;
            }
          }
          return true;
        });
        // 如果没有未使用的颜色，从已使用的颜色中选择使用次数最少的
        if (unusedColors.length === 0) {
          const usedColorsCount = new Map<string, number>();
          this.idToColorMap.forEach((color, usedId) => {
            usedColorsCount.set(color, (usedColorsCount.get(color) || 0) + 1);
          });
          const leastUsedColor = this.primaryColor.reduce((a, b) => {
            return (usedColorsCount.get(b) || 0) < (usedColorsCount.get(a) || 0) ? b : a;
          }, this.primaryColor[0]);
          this.idToColorMap.set(id, leastUsedColor);
        } else {
          // 从未使用的颜色中选择一个，并将其与ID关联
          const color = unusedColors[0];
          this.idToColorMap.set(id, color);
        }
      }
      return this.idToColorMap.get(id) || "";
    }
  }

  removeColorById(id: string): void {
    this.idToColorMap.delete(id);
  }
}

// 使用 ColorScheme 类生成颜色方案
export const colorScheme = new ColorScheme();
// 导出 ColorScheme 类的实例 colorScheme;

export const getToken = () => {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else {
    return sessionStorage.getItem("token");
  }
};
