/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SiteInfo } from "../types";

function rad(d: number) {
  return (d * Math.PI) / 180.0;
}
function deg(x: number) {
  return (x * 180) / Math.PI;
}
function getLonAndLat(lng: number, lat: number, brng: number, dist: number) {
  //大地坐标系资料WGS-84 长半径a=6378137 短半径b=6356752.3142 扁率f=1/298.2572236
  const a = 6378137;
  const b = 6356752.3142;
  const f = 1 / 298.257223563;

  const lon1 = lng * 1;
  const lat1 = lat * 1;
  const s = dist;
  const alpha1 = rad(brng);
  const sinAlpha1 = Math.sin(alpha1);
  const cosAlpha1 = Math.cos(alpha1);

  const tanU1 = (1 - f) * Math.tan(rad(lat1));
  const cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1),
    sinU1 = tanU1 * cosU1;
  const sigma1 = Math.atan2(tanU1, cosAlpha1);
  const sinAlpha = cosU1 * sinAlpha1;
  const cosSqAlpha = 1 - sinAlpha * sinAlpha;
  const uSq = (cosSqAlpha * (a * a - b * b)) / (b * b);
  const A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  const B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

  let sigma = s / (b * A),
    sigmaP = 2 * Math.PI;
  let cos2SigmaM = 0,
    sinSigma = 0,
    cosSigma = 0,
    deltaSigma = 0;
  while (Math.abs(sigma - sigmaP) > 1e-12) {
    cos2SigmaM = Math.cos(2 * sigma1 + sigma);
    sinSigma = Math.sin(sigma);
    cosSigma = Math.cos(sigma);
    deltaSigma =
      B *
      sinSigma *
      (cos2SigmaM +
        (B / 4) *
          (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
            (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
    sigmaP = sigma;
    sigma = s / (b * A) + deltaSigma;
  }

  const tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
  const lat2 = Math.atan2(
    sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
    (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp),
  );
  const lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
  const C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
  const L =
    lambda -
    (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

  const lngLatObj = { lng: lon1 + deg(L), lat: deg(lat2) };
  return lngLatObj;
}

export function GetCoordPoint(lng: number, lat: number, radius: number) {
  const result = [];
  for (let i = 0; i <= 360; i += 5) {
    const d = getLonAndLat(lng, lat, i, radius);
    result.push([d.lng, d.lat]);
  }
  return result;
}

interface Center {
  lat: number;
  lng: number;
}

export function generateFakePoint(center: Center, offsetDistance: number, offsetDeg: number) {
  const res: Center = center;
  if (offsetDeg > 0) {
    res.lng =
      center.lng +
      (offsetDistance * Math.sin((offsetDeg * Math.PI) / 180) * 180) /
        (Math.PI * 6371229 * Math.cos((center.lat * Math.PI) / 180));
    res.lat = center.lat + (offsetDistance * Math.cos((offsetDeg * Math.PI) / 180)) / ((Math.PI * 6371229) / 180);
  } else {
    return center;
  }
  return res;
}

export function getSiteCoord(siteId: string, siteList: any[]) {
  if (siteList.length === 0) return;
  for (const site of siteList) {
    if (site.site_id === siteId) {
      return site.coordinate;
    }
  }
  return null;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getEventSiteAlias(siteId: string, siteList: SiteInfo[]) {
  for (const site of siteList) {
    if (site.site_id === siteId) {
      return site.alias;
    }
  }
  return null;
}
