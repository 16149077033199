/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import "animate.css";
import "./index.scss";

interface IState {
  isSelect: boolean;
}

interface IProps {
  onChangeCallBack: any;
}

export default class Radio extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSelect: false,
    };
  }

  private onChange = () => {
    this.setState({ isSelect: !this.state.isSelect });
    this.props.onChangeCallBack(!this.state.isSelect);
    // localStorage.setItem("remember", String(!this.state.isSelect))
  };

  render() {
    if (this.state.isSelect) {
      return (
        <div className="radio" onClick={this.onChange}>
          <div className="radio-circle"></div>
          <div className="radio-mark animate__animated animate__headShake"></div>
        </div>
      );
    } else {
      return (
        <div className="radio" onClick={this.onChange}>
          <div className="radio-circle"></div>
        </div>
      );
    }
  }
}
