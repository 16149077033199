import zhMessages from "../messages/zh.json";

const Lan_zh = {
  messages: {
    ...zhMessages,
  },
  locale: "zh-Hans-CN",
};

export default Lan_zh;
