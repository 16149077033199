/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import MapBoxGl from "mapbox-gl";

export function UpdateGetSiteGeojson(layerId: string, map: MapBoxGl.Map | null, fetures: any[]) {
  const source: MapBoxGl.AnySourceImpl = map?.getSource(layerId) as MapBoxGl.AnySourceImpl;
  (source as any).setData({
    type: "FeatureCollection",
    features: fetures,
  });
  // trendMarkerIcon(map as MapBoxGl.Map)
}

export function getPulsingDot(map: MapBoxGl.Map) {
  const size = 200;

  // 创建图标的Image对象，但只需创建一次

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8ClampedArray(size * size * 4),
    context: document.createElement("canvas").getContext("2d") as CanvasRenderingContext2D,
    lastTimestamp: 0,

    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d") as CanvasRenderingContext2D;
    },
    render: function () {
      const duration = 1000;
      // const t = (performance.now() % duration) / duration;
      let t = (performance.now() % duration) / duration;

      const context = this.context;
      const numWaves = 5;
      const maxRadius = (size / 2) * 0.8; // Maximum radius for the waves
      const radiusIncrement = maxRadius / numWaves; // Incremental increase in radius for each wave

      // 绘制外圆，以描边的方式创建从中心向外扩散的波浪效果
      context.clearRect(0, 0, this.width, this.height);

      for (let i = 0; i < numWaves; i++) {
        const waveRadius = t * maxRadius - i * radiusIncrement;

        // Ensure the wave radius is always positive
        const currentWaveRadius = Math.max(0, waveRadius);

        // Draw each wave as a stroke
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, currentWaveRadius, 0, Math.PI * 2);
        context.strokeStyle = `rgba(100, 255, 100, ${1 - i / numWaves})`; // Green color, with alpha based on wave position
        context.lineWidth = 2;
        context.stroke();
      }

      // 更新图标的数据，获取画布中的数据
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // 持续重绘地图，实现脉动效果的平滑动画
      map.triggerRepaint();

      t = (t + 0.01) % 1;

      // 返回 `true` 通知地图图像已更新
      return true;
    },
  };

  return pulsingDot;
}

// export function trendMarkerIcon(map:MapBoxGl.Map){
//     const size = 400;

//     const pulsingDot = {
//         width: size,
//         height: size,
//         data: new Uint8ClampedArray(size * size * 4),
//         context: document.createElement("canvas").getContext("2d") as CanvasRenderingContext2D,
//         lastTimestamp: 0,
//         isAnimating: true,

//         onAdd: function () {
//             const canvas = document.createElement("canvas");
//             canvas.width = this.width;
//             canvas.height = this.height;
//             this.context = canvas.getContext("2d") as CanvasRenderingContext2D;
//         },

//         render: function (timestamp: number) {
//             if (!this.isAnimating) {
//                 return; // 如果标志变量为false，停止渲染
//             }
//             const duration = 2000; // 1秒
//             const t = (timestamp % duration) / duration;
//             map.setPaintProperty("siteSource", "icon-opacity", 0.5 + 0.5 * Math.cos(t * 2 * Math.PI));

//             // 请求下一帧动画
//             requestAnimationFrame(this.render.bind(this));
//         },
//         stopAnimation: function () {
//             this.isAnimating = false;
//         }
//     };

//     requestAnimationFrame(pulsingDot.render.bind(pulsingDot));
//     return pulsingDot;
// }

export function trendMarkerIcon(map: MapBoxGl.Map, type: string) {
  // const size = 120;
  let iconUrl = "";

  if (type === "plushDot-siteIcon") {
    iconUrl = "/icon/plushDotBase.png";
  } else if (type === "plushDot-camera-marker") {
    iconUrl = "/icon/plushDotCmera.png";
  } else if (type === "plushDot-hand-held") {
    iconUrl = "/icon/plushDotHandHeld.png";
  } else if (type === "online-siteIcon") {
    iconUrl = "/icon/onlineBase.png";
  } else if (type === "online-camera-siteIcon") {
    iconUrl = "/icon/onlineCamera.png";
  } else if (type === "online-hand-siteIcon") {
    iconUrl = "/icon/onlineHandHeld.png";
  }

  const pulsingDot = {
    width: 90,
    height: 150,
    data: new Uint8ClampedArray(90 * 150 * 4), // 初始化为图像数据的 Uint8ClampedArray
    // data:  new Uint8ClampedArray(iconUrl),  // 初始化为图像数据的 Uint8ClampedArray
    context: null as unknown as CanvasRenderingContext2D,
    lastTimestamp: 0,
    isAnimating: true,
    offsetY: 0,
    opacity: 1,
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d") as CanvasRenderingContext2D;
    },

    render: function () {
      const context = this.context;
      context.clearRect(0, 0, this.width, this.height);
      // 使用图像数据填充 data 属性
      const iconImage = new Image();
      iconImage.onload = () => {
        // const offsetY = Math.sin(performance.now() / 500) * 10; // 调整振幅和周期
        // context.drawImage(iconImage, 0, offsetY, this.width, this.height);
        // this.data = context.getImageData(0, 0, this.width, this.height).data;
        // const opacity = Math.abs(Math.sin(performance.now() / 500));
        // context.globalAlpha = opacity;
        // context.drawImage(iconImage, 0, 0, this.width, this.height);
        // this.data = context.getImageData(0, 0, this.width, this.height).data;
        // context.globalAlpha = 1;
      };
      iconImage.src = iconUrl;

      // 其他动画逻辑，例如根据时间调整图标的位置、颜色等

      return true;
    },
  };

  return pulsingDot;
}
