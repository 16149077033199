/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "./index.scss";
import React from "react";

interface TimeInfo {
  min: string;
  hour: string;
  ampm: string;
}

class TimeModal extends React.Component<any, any> {
  private timeText: TimeInfo;
  private timeInterval: number;

  constructor(props: any) {
    super(props);
    this.timeInterval = 0;
    this.timeText = {
      min: "",
      hour: "",
      ampm: "",
    };
  }

  componentDidMount() {
    this.timeInterval = window.setInterval(() => {
      this.formatTime(new Date());
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  private formatTime = (date: Date) => {
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    const minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    this.timeText.hour = hours < 10 ? "0" + hours.toString() : hours.toString();
    this.timeText.min = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    this.timeText.ampm = ampm;
    this.setState({});
  };

  render() {
    return (
      <div className="timeModal">
        <div className="ModalBg"></div>
        <div className="timeModalFont">
          <div>{this.timeText.hour}</div>
          <div className="timeDot">:</div>
          <div>{this.timeText.min}</div>
          <div>{this.timeText.ampm}</div>
        </div>
      </div>
    );
  }
}

export default TimeModal;
