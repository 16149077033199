/* eslint-disable @typescript-eslint/no-explicit-any */
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { SiteInfo, allDroneInfo } from "../../types";
import { Cache } from "../../utils/cache";
import { getSiteCoord } from "../../utils/coordTools";
import MapBoxGl from "mapbox-gl";

const getDroneMarker = () => {
  const droneMarker = document.createElement("div");
  droneMarker.innerHTML = `
    <div class="droneMarker">
        <div class="Nopoint" style="cursor: pointer;">
            <div class="drone"></div>
        </div>
    </div>`;
  return droneMarker;
};

interface IProps {
  map: mapboxgl.Map;
  drone: allDroneInfo;
  intl: any;
  siteList: SiteInfo[];
  droneLocCache: Cache;
  droneSmallMarkerList: MapBoxGl.Marker[];
  data: allDroneInfo[];
  siteCache: Cache;
}

const DroneMarkerComponent: React.FC<IProps> = props => {
  const [drnMarker, setMarkers] = useState<mapboxgl.Marker | undefined>();
  const drnMarkerRef = useRef(drnMarker);
  function truncateString(str: string, maxLength: number) {
    if (str.length <= maxLength) {
      return str;
    } else {
      const prefix = str.slice(0, 6);
      const suffix = str.slice(-6);
      return prefix + "..." + suffix;
    }
  }
  const droneData = props.drone.data[0];

  const getPopupContent = (marker: any) => {
    const closeBtn = document.createElement("div");
    closeBtn.className = "closeDroneCard";
    closeBtn.addEventListener("click", () => {
      marker.getPopup()?.remove();
    });
    const popupContent = document.createElement("div");
    popupContent.className = "grid-container";
    popupContent.appendChild(closeBtn);
    // 添加 ID 信息
    const idInfo = document.createElement("div");
    idInfo.className = "lineName";
    idInfo.innerHTML = `
        <div class="IDLeftbg"></div>
        <div class="grid-item attribute">ID</div>
        <div class="grid-item info">${truncateString(droneData.drone_id, 12)}</div>
    `;
    popupContent.appendChild(idInfo);

    // 添加类型信息
    const typeInfo = document.createElement("div");
    typeInfo.className = "lineName";
    typeInfo.innerHTML = `
        <div class="grid-item attribute">${props.intl.formatMessage({ id: "homePage.drones.type" })}</div>
        <div class="grid-item info">${truncateString(droneData.name, 12)}</div>
    `;
    popupContent.appendChild(typeInfo);

    // 添加站点名称信息
    {
      props.drone.sites !== null &&
        props.drone.sites.map((site: string, index: number) => {
          const siteNameInfo = document.createElement("div");
          siteNameInfo.className = "lineName";
          siteNameInfo.innerHTML = `
            <div class="grid-item attribute">${index === 0 ? props.intl.formatMessage({ id: "homePage.eventsList.column.siteName" }) : ""}</div>
            <div class="grid-item info">${props.siteCache.get(site)?.alias ? truncateString(props.siteCache.get(site)?.alias, 12) : truncateString(site, 12)}</div>
            `;
          popupContent.appendChild(siteNameInfo);
        });
    }

    // 添加速度信息
    const speedInfo = document.createElement("div");
    speedInfo.className = "lineName";
    speedInfo.innerHTML = `
        <div class="grid-item attribute">${props.intl.formatMessage({ id: "Map.Speed" })}</div>
        <div class="grid-item info">${droneData.speed === undefined ? 0 : droneData.speed.toFixed(2)}m/s</div>
    `;
    popupContent.appendChild(speedInfo);
    // 添加距离信息
    if (droneData?.distance) {
      const distanceInfo = document.createElement("div");
      distanceInfo.className = "lineName";
      distanceInfo.innerHTML = `
          <div class="grid-item attribute">${props.intl.formatMessage({ id: "Map.Distance" })}</div>
          <div class="grid-item info">${droneData.distance}m</div>
      `;
      popupContent.appendChild(distanceInfo);
    }
    // 添加坐标信息
    if (droneData?.coordinate?.latitude && droneData?.coordinate?.longitude) {
      const coordInfo = document.createElement("div");
      coordInfo.className = "lineName";
      coordInfo.innerHTML = `
            <div class="grid-item attribute attributeLast"></div>
            <div class="grid-item info droneMarkerInfo ">
            <div class="attributeAll">
            ${droneData.coordinate.latitude.toFixed(2) + "," + droneData.coordinate.longitude.toFixed(2)}
            </div>
            <div class="droneMarkerIcon"></div>
            </div>
        `;
      popupContent.appendChild(coordInfo);
    }
    return popupContent;
  };

  //生成无人机图标
  const addDroneMarker = () => {
    if (droneData?.coordinate?.latitude && droneData?.coordinate?.longitude) {
      const markerId = droneData.drone_id;
      if (droneData?.coordinate?.latitude > 90 || droneData?.coordinate?.latitude < -90) return;
      const marker = new mapboxgl.Marker(getDroneMarker())
        .setLngLat({
          lat: droneData?.coordinate?.latitude,
          lng: droneData?.coordinate?.longitude,
        }) // Replace with the marker"s longitude and latitude
        .addTo(props.map);

      // ... (其他弹出框内容)

      marker.setPopup(
        // eslint-disable-next-line prettier/prettier
        new mapboxgl.Popup({ anchor: "bottom", className: "infoMarker", closeButton: false, closeOnClick: false }).setDOMContent(getPopupContent(marker)));

      // let siteAlias = props.siteList.map((sitesItem) => {
      //     if (sitesItem.site_id == props.drone.siteId) {
      //         return (sitesItem.alias ? sitesItem.alias : props.drone.siteId)
      //     }
      // });
      marker.getElement()?.setAttribute("data-marker-id", markerId);
      props.droneSmallMarkerList.push(marker);

      return marker;
    }
    //下边注释代码是  当无人机无经纬度时，前端手动添加虚拟坐标
    // let fakePoint;
    // const droneId = droneData.drone_id;
    // if (!droneData?.coordinate?.latitude || !droneData?.coordinate?.longitude) {
    //     let centerPoint = getSiteCoord(props.drone.siteId, props.siteList);
    //     if (!centerPoint) {
    //         console.log("no site id")
    //         return;
    //     }
    //     if (props.droneLocCache?.get(droneId)) {
    //         fakePoint = props.droneLocCache?.get(droneId);
    //     } else {
    //         fakePoint = generateFakePoint(
    //             { lng: centerPoint.longitude, lat: centerPoint.latitude },
    //             getRandomInt(2000, 5000),
    //             droneData.direction !== 365 ? droneData.direction : getRandomInt(0, 360)
    //         );
    //         props.droneLocCache?.set(droneId, fakePoint);
    //     }
    // }
    // const markerId = droneData.drone_id;
    // var marker = new mapboxgl.Marker(getDroneMarker())
    //     .setLngLat({
    //         lat: droneData?.coordinate?.latitude ? droneData?.coordinate?.latitude : fakePoint?.lat,
    //         lng: droneData?.coordinate?.longitude ? droneData?.coordinate?.longitude :  fakePoint?.lng
    //     }) // Replace with the marker"s longitude and latitude
    //     .addTo(props.map);

    //     // ... (其他弹出框内容)
    //     marker.setPopup(new mapboxgl.Popup({ anchor: "bottom", className: "infoMarker", closeButton: false, closeOnClick: false }).setDOMContent(getPopupContent(marker)));

    //     // let siteAlias = props.siteList.map((sitesItem) => {
    //     //     if (sitesItem.site_id == props.drone.siteId) {
    //     //         return (sitesItem.alias ? sitesItem.alias : props.drone.siteId)
    //     //     }
    //     // });
    //     marker.getElement()?.setAttribute("data-marker-id", markerId);
    //     props.droneSmallMarkerList.push(marker);

    //     return marker;
    //----------------到这行
  };

  useEffect(() => {
    // 组件卸载
    return () => {
      drnMarkerRef.current && drnMarkerRef.current.remove();
      // 在这里进行一些资源释放或取消订阅等操作
    };
  }, []);

  useEffect(() => {
    //判断逻辑，首先有站点，然后检测到无人机的站点属于前面的站点列表，属于的话就会返回经纬度，不属于返回null也就不会执行函数了，最后一个是判断是否有图标
    if (props.siteList.length && getSiteCoord(props.drone.sites[0], props.siteList) && !drnMarker) {
      const drnMarker = addDroneMarker();
      setMarkers(drnMarker);
      drnMarkerRef.current = drnMarker;
    }
    // 在这里可以处理数据更新后的逻辑
    // 模拟处理数据的操作
  }, [props.drone.sites, props.siteCache.get(props.drone.sites[0])?.alias]); // 当data发生变化时执行
  useEffect(() => {
    if (drnMarker) {
      drnMarker.getPopup().setDOMContent(getPopupContent(drnMarker) as Node);
    }
  }, [
    props.drone.sites !== null &&
      props.drone.sites.map(site => {
        return props.siteCache.get(site)?.alias;
      }),
    localStorage.getItem("Local"),
    droneData.distance,
    droneData.speed,
  ]);

  useEffect(() => {
    if (drnMarker && droneData.coordinate?.latitude && droneData.coordinate?.longitude) {
      if (droneData?.coordinate?.latitude > 90 || droneData?.coordinate?.latitude < -90) return;
      drnMarker.setLngLat({
        lat: droneData.coordinate?.latitude,
        lng: droneData.coordinate?.longitude,
      });
    }
  }, [droneData.coordinate?.latitude, droneData.coordinate?.longitude]); // 当data发生变化时执行
  return <></>;
};

export default DroneMarkerComponent;
