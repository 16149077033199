import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SiteInfo } from "../../types";

export interface curSiteInfo {
  curSiteInfo: SiteInfo[];
  curTabKey: number;
}

const initialState: curSiteInfo = {
  curSiteInfo: [],
  curTabKey: 0,
};

export const curSiteInfoSlice = createSlice({
  name: "curSiteInfo",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateSiteInfo: (state: curSiteInfo, action: PayloadAction<SiteInfo>) => {
      let needAdd = true;
      state.curSiteInfo.forEach((element, index) => {
        if (action.payload.site_id === element.site_id) {
          state.curSiteInfo[index] = action.payload;
          needAdd = false;
        }
      });
      if (needAdd) {
        state.curSiteInfo.push(action.payload);
      }
    },
    removeSiteById: (state: curSiteInfo, action: PayloadAction<SiteInfo>) => {
      state.curSiteInfo.forEach((element, index) => {
        if (action.payload.site_id === element.site_id) {
          state.curSiteInfo.splice(index, 1);
        }
      });
    },
    //删除多个离线站点
    removeSiteByIds: (state: curSiteInfo, action: PayloadAction<string[]>) => {
      const idsToRemove = action.payload;
      state.curSiteInfo = state.curSiteInfo.filter(elemnt => !idsToRemove.includes(elemnt.site_id));
    },
    initSiteInfo: (state: curSiteInfo, action: PayloadAction<SiteInfo[]>) => {
      state.curSiteInfo = action.payload;
    },
    setCurTabKey: (state: curSiteInfo, action: PayloadAction<number>) => {
      state.curTabKey = action.payload;
    },
  },
});

export const { updateSiteInfo, initSiteInfo, removeSiteById, setCurTabKey, removeSiteByIds } = curSiteInfoSlice.actions;

export default curSiteInfoSlice.reducer;
