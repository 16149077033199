import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DroneInfo {
  attacking_ttl: number;
  confirmed: boolean;
  detected_freq: number;
  direction: number;
  distance: number;
  drone_id: string;
  drone_type: string;
  image_url: string;
  name: string;
  severity: number;
  speed: number;
  can_attack: boolean;
  attacking: boolean;
  attack_type: string;
  whitelisted: boolean;
  coordinate?: Coord;
  tracing?: Tracing;
}
interface Coord {
  latitude: number;
  longitude: number;
  altitude: number;
  height: number;
}
interface Tracing {
  origin: Origin;
  points: Points[];
}
interface Origin {
  altitude: number;
  height: number;
  latitude: number;
  longitude: number;
}

interface Points {
  x: number;
  y: number;
}

interface SiteDrones {
  data: DroneInfo[] | [];
  drone_id: string;
  sites: string[];
}

const initialState: SiteDrones[] = [];

export const curDroneInfoSlice = createSlice({
  name: "curDroneInfoSlice",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateDroneInfo: (state: SiteDrones[], action: PayloadAction<SiteDrones[]>) => {
      action.payload.forEach(updateDrone => {
        const index = state.findIndex(drone => drone.drone_id === updateDrone.drone_id);
        if (index !== -1) {
          if (updateDrone.sites === null) {
            state.splice(index, 1);
          } else {
            state[index] = updateDrone;
          }
        } else {
          state.push(updateDrone);
        }
      });
    },
    initDroneInfo: (state: SiteDrones[], action: PayloadAction<SiteDrones[]>) => {
      if (!action.payload) {
        return;
      } else {
        try {
          return action.payload;
        } catch (error) {
          console.log("init drone info error", error);
        }
      }
    },
  },
});

export const { updateDroneInfo, initDroneInfo } = curDroneInfoSlice.actions;

export default curDroneInfoSlice.reducer;
