/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import mapboxgl from "mapbox-gl";
import { message } from "antd";
import globalWS from "../../globle";
import { getToken } from "../util";
import "./common.scss";

interface OpProps {
  param: GridRenderCellParams;
  map?: mapboxgl.Map;
  handleModal: any;
  intl?: any;
  data?: any;
}

export const OpCellCompent: React.FC<OpProps> = props => {
  const canEnterSubSite = (type: string, online: boolean) => {
    return online && (type === "BASE" || type === "CAMERA" || type === "PORTABLE");
  };

  const handleClickProxy = (param: GridRenderCellParams, status: number) => {
    if (canEnterSubSite(param.row?.siteType, param.row?.isOnline)) {
      //打开代理
      const token: string | null = getToken();
      if (token == null || param.row.siteId === "") {
        console.warn("token/id is null");
        return;
      }
      const siteID = param.row.siteId;
      const data = {
        code: 105,
        data: {
          site_id: siteID,
          operation: status,
          context: "enter-sub",
        },
      };
      const ws = globalWS.getWS();

      ws?.send(JSON.stringify(data));
    } else {
      message.error(props.intl.formatMessage({ id: "message.info.enterSub.notSupport" }));
    }
  };
  return (
    <div>
      {localStorage.getItem("deviceBool") === "1" && (
        <Tooltip title={props.intl.formatMessage({ id: "SiteList.operate.enterSubsite.title" })} arrow>
          <span>
            <div
              className={
                canEnterSubSite(props.param.row?.siteType, props.param.row?.isOnline)
                  ? "enterSubsite"
                  : "noEnterSubsite"
              }
              onClick={() => {
                handleClickProxy(props.param, 1);
              }}
            ></div>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export const PositionCell: React.FC<OpProps> = props => {
  const handleClickLocation = (param: GridRenderCellParams) => {
    let centerPoint;
    props.data.forEach((item: any) => {
      if (item.site_id === param.row.siteId) {
        if (item.range === 500) {
          centerPoint = { lat: param.row.coord.latitude + 0.002, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 15.2,
              speed: 1,
              essential: true,
            });
          }
        } else if (item.range === 1000) {
          centerPoint = { lat: param.row.coord.latitude + 0.003, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 14.3,
              speed: 1,
              essential: true,
            });
          }
        } else if (item.range === 3000) {
          centerPoint = { lat: param.row.coord.latitude + 0.009, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 12.7,
              speed: 1,
              essential: true,
            });
          }
        } else if (item.range === 5000) {
          centerPoint = { lat: param.row.coord.latitude + 0.012, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 12,
              speed: 1,
              essential: true,
            });
          }
        } else if (item.range === 8000) {
          centerPoint = { lat: param.row.coord.latitude + 0.025, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 11.2,
              speed: 1,
              essential: true,
            });
          }
        } else {
          centerPoint = { lat: param.row.coord.latitude, lng: param.row.coord.longitude };
          if (param.row.coord.latitude > -90 && param.row.coord.latitude < 90) {
            props.map?.flyTo({
              center: centerPoint,
              zoom: 12,
              speed: 1,
              essential: true,
            });
          }
        }
      }
    });
    setTimeout(() => {
      props.handleModal("site", false);
    });
  };
  return (
    <div
      className="slteListPositionAll"
      onClick={() => {
        handleClickLocation(props.param);
      }}
    >
      <Tooltip
        className="slteListPositionAll"
        title={props.intl.formatMessage({ id: "SiteList.renderCell.position" })}
        arrow
      >
        <span>
          <div className="siteListAllPosition"></div>
          {props.param.row.coord.longitude.toFixed(4) + ", " + props.param.row.coord.latitude.toFixed(4)}
        </span>
      </Tooltip>
    </div>
  );
};

interface IProps {
  isOnline: boolean;
}

export function OnlineCell(props: IProps) {
  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 2,
        },
      }}
    >
      {props.isOnline ? (
        <CloudDoneOutlinedIcon color="primary" titleAccess="rename"></CloudDoneOutlinedIcon>
      ) : (
        <CloudOffOutlinedIcon color="secondary" titleAccess="rename"></CloudOffOutlinedIcon>
      )}
    </Box>
  );
}
interface AliasProps {
  param: any;
}

function truncateString(str: string, maxlength: number) {
  if (str.length > maxlength) {
    const start = str.slice(0, 7);
    const end = str.slice(-7);
    return start + "..." + end;
  }
  return str;
}

export function AliasCell(props: AliasProps) {
  return (
    <Tooltip title={props.param} arrow>
      <span>{truncateString(props.param, 16)}</span>
    </Tooltip>
  );
}
export function DescCell(props: AliasProps) {
  return (
    <Tooltip title={props.param} arrow>
      {/* <span>{truncateString(props.param,16)}</span>  */}
      <span className="renderDesc">{props.param}</span>
    </Tooltip>
  );
}

interface StatusPros {
  isFault: boolean;
  intl: any;
}

export function StatusCell(props: StatusPros) {
  return (
    <div
      className={
        props.isFault === props.intl.formatMessage({ id: "homePage.siteOverview.fault" })
          ? "siteListFaultIcon siteListAllIcn"
          : props.isFault === props.intl.formatMessage({ id: "homePage.siteOverview.online" })
            ? "siteListOnlineIcon siteListAllIcn"
            : props.isFault === props.intl.formatMessage({ id: "homePage.siteOverview.alert" })
              ? "siteListWarnIcon siteListAllIcn"
              : "siteListOfflineIcon siteListAllIcn"
      }
    ></div>
  );
}
interface SitePros {
  isType: string;
  intl: any;
}
export function SiteTypeCell(props: SitePros) {
  switch (props.isType) {
    case "CAMERA":
      return (
        <div className="slteListTypeAll">
          <div className="siteListCamera siteListAllType"></div>
          {props.intl.formatMessage({ id: "SiteList.Type.camera" })}
        </div>
      );
    case "PORTABLE":
      return (
        <div className="slteListTypeAll">
          <div className="siteListPB siteListAllType"></div>
          {props.intl.formatMessage({ id: "SiteList.Type.PB" })}
        </div>
      );
    case "BASE":
      return (
        <div className="slteListTypeAll">
          <div className="siteListRf siteListAllType"></div>
          {props.intl.formatMessage({ id: "SiteList.Type.rf" })}
        </div>
      );
    case "HANDHELD":
      return (
        <div className="slteListTypeAll">
          <div className="siteListHand siteListAllType"></div>
          {props.intl.formatMessage({ id: "SiteList.Type.hand" })}
        </div>
      );
    default:
      return (
        <div className="slteListTypeAll">
          <div className="siteListRf siteListAllType"></div>
          {props.intl.formatMessage({ id: "SiteList.Type.Unkonw" })}
        </div>
      );
  }
}
