import React from "react";
import { SiteInfo, allDroneInfo } from "../../types";
import mapboxgl from "mapbox-gl";
import MapBoxGl from "mapbox-gl";
import DroneItem from "./DroneItem";
import { Cache } from "../../utils/cache";
import "./index.scss";

interface IProps {
  data: allDroneInfo[];
  sites: SiteInfo[];
  droneSmallMarkerList: MapBoxGl.Marker[];
  globalMap?: mapboxgl.Map;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intl?: any;
  siteCache: Cache;
}
interface IState {
  showListFirstContent: boolean;
  clickedSiteId: string | null;
  expanded: boolean;
}

class DroneList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showListFirstContent: false,
      clickedSiteId: null,
      expanded: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <div className="droneList">
        <div className="droneListHeader">{this.props.intl.formatMessage({ id: "homePage.drones.staticCard" })}</div>
        <div className="droneListBody">
          {this.props.data.length === 0 && (
            <div className="noData">{this.props.intl.formatMessage({ id: "homePage.not.drones" })}</div>
          )}
          {this.props.data.map(item => (
            <DroneItem
              key={item.drone_id}
              drone={item.data[0]}
              allSites={item.sites}
              intl={this.props.intl}
              sites={this.props.sites}
              droneSmallMarkerList={this.props.droneSmallMarkerList}
              siteCache={this.props.siteCache}
            ></DroneItem>
          ))}
        </div>
      </div>
    );
  }
}

export default DroneList;
