/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { AlignItemsList } from "../../utils/mui/List";
import { Jammer } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./index.scss";
import moment from "moment";
import FlvPlayer from "./video";
import globalWS from "../../globle";
import { Cache } from "../../utils/cache";
import { getToken } from "../../utils/util";
import { Card, Tabs } from "antd";

interface Props {
  id: string;
  online: boolean;
  state: number;
  gps: [number, number];
  model?: string;
  isMaster?: boolean; //是否开启组网
  childTotal?: number;
  childCurCount?: number;
  bands: Jammer[];
  version: string;
  autoAtck?: boolean;
  enableDetected?: boolean;
  alias: string;
  intl: any;
  lastConnectTime: string;
  alertAllShow: any;
  alertContent: any;
  siteCache: Cache;
  type: string;
}

const CameraCompent: React.FC<Props> = props => {
  const videoUrl = useSelector((state: RootState) => state.videoReducer.url);
  const onChange = (key: string) => {
    if (key === "1") {
      OpenVideoProxy(props.id, 1);
    }
  };
  function truncateString(str: string, maxLength: number) {
    if (str.length <= maxLength) {
      return str;
    } else {
      const prefix = str.slice(0, 6);
      const suffix = str.slice(-6);
      return prefix + "..." + suffix;
    }
  }

  return (
    <div>
      <Card
        className="popCarsClassName"
        title={
          <div className="popHeader">
            <div className="popHeaderName" title={props.alias === "" ? props.id : props.alias}>
              {props.intl.formatMessage({ id: "Mui.pop.SiteName" })}:{" "}
              <span>{truncateString(props.alias === "" ? props.id : props.alias, 14)}</span>
            </div>
            <div className="popHeaderTimeLabel">{props.intl.formatMessage({ id: "Mui.pop.loginTime" })}</div>
            <div className="popHeaderTimeValue">{moment(props.lastConnectTime).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        }
      >
        <div className="cardTab">
          <Tabs
            defaultActiveKey="0"
            tabPosition="bottom"
            onChange={onChange}
            items={[
              {
                label: (
                  <div className="cardFirstAll">
                    <div className="cardFirstTab"></div>
                    <div className="cardFirstBottom"></div>
                  </div>
                ),
                key: "0",
                children: AlignItemsList({ ...props }),
              },
              {
                label: (
                  <div className="cardAnotherAll">
                    <div className="cardAnotherTab"></div>
                    <div className="cardAnotherBottom"></div>
                  </div>
                ),
                key: "1",
                children: <div className="videoName">{videoUrl && <FlvPlayer url={videoUrl}></FlvPlayer>}</div>,
              },
            ]}
          />
          <div className="cardTabMiddle"></div>
        </div>
      </Card>
    </div>
  );
};

const OpenVideoProxy = (siteId: string, status: number) => {
  //打开代理
  const token: string | null = getToken();
  if (token == null || !siteId) {
    console.warn("token/id is null");
    return;
  }
  const data = {
    code: 105,
    data: {
      site_id: siteId,
      operation: status,
      context: "video-url",
    },
  };
  const ws = globalWS.getWS();
  ws?.send(JSON.stringify(data));
};

export default CameraCompent;
