import mapboxgl from "mapbox-gl";
import { Websocket } from "websocket-ts/lib";

class WebSocket {
  // 字段
  ws: Websocket | null;

  // 构造函数
  constructor(ws: Websocket | null) {
    this.ws = ws;
  }

  // 方法
  getWS(): Websocket | null {
    console.log("获取websocket 实例  :   ", this.ws);
    return this.ws;
  }
  setWS(ws: Websocket): void {
    console.log("更新websocket 实例  :   ", ws);
    this.ws = ws;
  }
}

// 创建一个对象
const globalWS = new WebSocket(null);
export default globalWS;

class GlobalMap {
  // 字段
  map: mapboxgl.Map | null;

  // 构造函数
  constructor(map: mapboxgl.Map | null) {
    this.map = map;
  }

  // 方法
  getMap(): mapboxgl.Map | null {
    console.log("获取map 实例  :   ", this.map);
    return this.map;
  }
  setMap(map: mapboxgl.Map | null): void {
    console.log("map 实例  :   ", map);
    this.map = map;
  }
}
// 创建一个对象
const globalMap = new GlobalMap(null);

export { globalMap, globalWS };
