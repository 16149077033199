import ruMessages from "../messages/ru_RU.json";

const ru_RU = {
  messages: {
    ...ruMessages,
  },
  locale: "ru-RU",
};

export default ru_RU;
