/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from "antd";
import axios, { Method, AxiosResponse } from "axios";

export const baseURL =
  process.env.NODE_ENV === "development" ? "https://192.168.85.221/v1/" : window.location.origin + "/v1/";
const request = <T extends { code: number }>(
  method: Method,
  path: string,
  data?: any,
  headers?: any,
  params?: any,
): Promise<AxiosResponse<T>> => {
  return axios
    .request<T>({
      method: method,
      url: baseURL + path,
      data: data,
      headers: headers,
      params: params,
    })
    .then(response => {
      // 检查返回码
      if (response.data.code === -120 && !params) {
        if (localStorage.getItem("Local") === "zh_CN") {
          message.error("无操作权限");
        } else if (localStorage.getItem("Local") === "en_US") {
          message.error("No operation permission");
        } else if (localStorage.getItem("Local") === "ru_RU") {
          message.error("Нет разрешения на выполнение операции");
        }
        // message.error(intl.formatMessage({ id: "account.userList.alloperationAuth" }));
        return Promise.reject({
          response: {
            status: 403, // 403 表示禁止访问
            statusText: "Forbidden",
          },
        });
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      // 处理其他错误
      return Promise.reject(error);
    });
};

export function getWsURL() {
  const wssURL = process.env.NODE_ENV === "development" ? `wss://192.168.85.221` : `wss://${window.location.host}`;
  return wssURL;
}

export const getStaticUrl = (maptype: string, path: string) => {
  if (path.substring(0, 1) === "/") {
    return process.env.NODE_ENV === "development" ? "https://192.168.85.221" + path : window.location.origin + path;
  } else {
    return process.env.NODE_ENV === "development"
      ? "https://192.168.85.221/" + path
      : window.location.origin + "/" + path;
  }
};

export const buildApiUrl = (url: string) => {
  return process.env.NODE_ENV === "development" ? "https://192.168.85.221" + url : window.location.origin + url;
};

export default request;
