import enMessages from "../messages/en.json";

const EnLang = {
  messages: {
    ...enMessages,
  },
  locale: "en-US",
};

export default EnLang;
