/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ReactDOM from "react-dom";
import MapBoxGl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { GetCoordPoint } from "../../utils/coordTools";
import { CompassControl, ZoomControl } from "mapbox-gl-controls";
import RulerControl from "./lib/RulerControl/RulerControl";
import {
  MapboxStyleDefinition,
  MapboxStyleSwitcherControl,
  MapboxStyleSwitcherOptions,
} from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import "./index.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import PopUpCompent from "../Mui/Pop";
import CamComponent from "../Mui/Camera";
import { getPulsingDot, UpdateGetSiteGeojson } from "../../utils/getSiteGeo";
import { Jammer, SiteInfo, allDroneInfo } from "../../types";
import { globalMap } from "../../globle";
import { Cache } from "../../utils/cache";
import mapboxgl from "mapbox-gl";
import DroneMarkerComponent from "./droneMarker";
import request, { buildApiUrl, getStaticUrl } from "../../api/request";
import { Provider } from "react-redux";
import { store } from "../../redux/store";
import TraceComponent from "./droneTrace";
import { allShowControl } from "./allShow";
import { getToken } from "../../utils/util";
import { transformStyleJson, transformToMapLan } from "./mapUtil";

interface Coord {
  latitude: number;
  longitude: number;
  altitude: number;
  height: number;
}

//聚合数据类型
interface GeoJSONFeature {
  type: "Feature";
  properties: any;
  geometry: {
    type: "Point";
    coordinates: [number, number];
    alias: string;
  };
}

interface GeoJSONFeatureCollection {
  type: "FeatureCollection";
  features: GeoJSONFeature[];
}

interface IProps {
  mapCenter: Coord;
  siteList: SiteInfo[];
  droneList: allDroneInfo[];
  initMapInfo: any;
  intl: any;
  alertAllShow: any;
  alertContent: any;
  droneSmallMarkerList: MapBoxGl.Marker[];
  data: allDroneInfo[];
  siteCache: Cache;
  droneTraceShow: boolean;
  globalSetting: any;
}

interface IState {
  tabKey: number;
  initCenter: boolean;
  popup: any;
  tipShow: any;
  selectedFeatureId: any;
  isMapLoaded: boolean;
  offlineMapUrl: string;
  mapType: string;
  sateMapUrl: string;
  vectorMapUrl: string;
  mapStyleLoaded: boolean;
  polymerizationPopup: MapBoxGl.Popup | null;
  isPopupOpen: boolean;
  hasExecutedPopupLogic: boolean;
  pupuObj: any;
  mapChangeType: boolean;
}

class Map extends React.Component<IProps, IState> {
  private map: MapBoxGl.Map | null;
  private readonly accessToken: string;
  private styleLoded: boolean;
  private featuers: any[];
  private radarFeatures: any[];
  private droneLocCache: Cache;
  private languageCtl: MapboxLanguage;
  private siteIds: { [key: string]: { sourceId: string; layerId: string } };
  private rulerControl: RulerControl;
  private fillColorInterval: any;

  constructor(props: IProps) {
    super(props);
    this.map = null;
    this.styleLoded = false;
    this.accessToken = "pk.eyJ1IjoibHpjY3MiLCJhIjoiY2xvemlmOGJzMDB2bTJxbXIxeTlvemlzcSJ9.Asvw-xMAcHVDgfP6uxMRHQ";
    this.featuers = [];
    this.radarFeatures = [];
    this.rulerControl = new RulerControl();

    this.state = {
      tabKey: 0,
      initCenter: false,
      popup: null,
      tipShow: {},
      selectedFeatureId: null,
      isMapLoaded: false,
      mapStyleLoaded: false,
      offlineMapUrl: "",
      mapType: "",
      sateMapUrl: "",
      vectorMapUrl: "",
      polymerizationPopup: null,
      isPopupOpen: false,
      hasExecutedPopupLogic: false,
      pupuObj: [],
      mapChangeType: true,
    };
    this.droneLocCache = new Cache();
    this.languageCtl = new MapboxLanguage({ defaultLanguage: this.getMapLan() });
    this.siteIds = {};
    this.fillColorInterval = null;
  }
  // 点击站点的弹窗
  private addPopup = (el: any, lat: number, lng: number, map: MapBoxGl.Map) => {
    let placeholder: HTMLElement;
    const { popup } = this.state;
    if (document.getElementById("sitePop")) {
      placeholder = document.getElementById("sitePop") as HTMLElement;
    } else {
      placeholder = document.createElement("div");
      placeholder.id = "sitePop";
    }
    placeholder.style.height = "100%";
    placeholder.style.width = "100%";
    ReactDOM.render(el, placeholder);
    //这是设置点击的时候，直接穿透，让当前坐标的站点都触发点击事件。
    const marker = new MapBoxGl.Popup({ className: "siteMarker" })
      .setDOMContent(placeholder)
      .setLngLat({ lng: lng, lat: lat })
      .addTo(map as MapBoxGl.Map);
    if (popup) {
      popup.remove();
    }
    this.setState({ popup: marker });
    marker.on("open", function () {
      const popupPos = marker.getLngLat();
      const bounds = map.getBounds();
      // 根据相对于地图边界的位置调整 popup 的 offset
      if (popupPos.lng < bounds.getSouth()) {
        // Popup 靠近左边界，调整 offset 到右边
        marker.setOffset([10, 0]); // 根据实际情况调整偏移量
      } else if (popupPos.lng > bounds.getNorth()) {
        // Popup 靠近右边界，调整 offset 到左边
        marker.setOffset([-10, 0]); // 根据实际情况调整偏移量
      } else {
        // Popup 在地图范围内，使用默认 offset
        marker.setOffset([0, 0]);
      }
    });
    return marker;
  };

  //获取地图token
  getAccessToken = () => {
    return new Promise<boolean>((resolve, reject) => {
      interface Response {
        data: [{ key: string; value: string }];
        code: number;
      }
      const token: string | null = getToken();
      const header: any = {
        "Content-Type": "multipart/form-data",
        token: token,
      };
      request("get", "config", "", header, { key: "mapbox-token" })
        .then(res => {
          const rep: Response = res.data as Response;
          if (rep.code === 0) {
            //success
            MapBoxGl.accessToken = rep.data[0].value;
            resolve(true);
          } else {
            //fail
            console.log("get accesstoken err");
            reject(false);
          }
        })
        .catch(err => {
          reject(false);
          console.log("get accesstoken err", err);
        });
    });
  };

  //获取地图类型
  getMapType = () => {
    return new Promise<boolean>((resolve, reject) => {
      interface Response {
        data: [{ key: string; value: string }];
        code: number;
      }
      const token: string | null = getToken();
      const header: any = {
        "Content-Type": "multipart/form-data",
        token: token,
      };
      request("get", "config", "", header, { key: "mapType" })
        .then(res => {
          const rep: Response = res.data as Response;
          if (rep.code === 0) {
            //success
            const mapType = rep.data[0].value;
            this.setState({ mapType: mapType });
            request("get", `config?key=${mapType + "-sat"}&&key=${mapType + "-vec"}`, "", header).then(res => {
              const resp: Response = res.data as Response;
              if (resp.code === 0) {
                resp.data.forEach(item => {
                  if (item.key === mapType + "-sat") {
                    this.setState({ sateMapUrl: item.value });
                  } else if (item.key === mapType + "-vec") {
                    this.setState({ vectorMapUrl: item.value });
                  }
                });
                resolve(true);
              } else {
                reject(false);
              }
            });
          } else {
            //fail
            console.log("get mapType err");
            reject(false);
          }
        })
        .catch(err => {
          reject(false);
          console.log("get mapType err", err);
        });
    });
  };

  //获取地图语言
  private getMapLan = () => {
    switch (this.props.intl.locale) {
      case "zh-Hans-CN":
        return "zh-Hans";
      case "en-US":
        return "en";
      case "ru-RU":
        return "ru";
      default:
        return "en";
    }
  };

  private droneIsInSite = (siteId: string): boolean => {
    return this.props.droneList.some(drone => drone.sites.includes(siteId));
  };

  //不同站点类型对应弹窗
  private getRenderComp = (
    type: string,
    id: string,
    online: boolean,
    state: number,
    gps: any,
    bands: Jammer[],
    version: string,
    intl: any,
    lastConnectTime: string,
    alertAllShow: any,
    alertContent: any,
    model: string,
    alias: string,
    autoAtk: boolean,
    enableDetected?: boolean,
    isMaster?: boolean,
  ) => {
    switch (type) {
      case "CAMERA":
        return (
          <Provider store={store}>
            <CamComponent
              key="sitePop"
              id={id}
              online={online}
              state={state}
              model={model}
              gps={gps}
              bands={bands}
              version={version}
              intl={intl}
              alias={alias}
              lastConnectTime={lastConnectTime}
              alertAllShow={alertAllShow}
              alertContent={alertContent}
              autoAtck={autoAtk}
              siteCache={this.props.siteCache}
              type={type}
            />
          </Provider>
        );
      case "BASE":
        return (
          <PopUpCompent
            key="sitePop"
            id={id}
            online={online}
            state={state}
            model={model}
            gps={gps}
            bands={bands}
            version={version}
            intl={intl}
            alias={alias}
            lastConnectTime={lastConnectTime}
            alertAllShow={alertAllShow}
            alertContent={alertAllShow}
            autoAtck={autoAtk}
            type={type}
          />
        );
      case "HANDHELD":
        return (
          <PopUpCompent
            key="sitePop"
            id={id}
            online={online}
            state={state}
            gps={gps}
            model={model}
            bands={bands}
            version={version}
            intl={intl}
            alias={alias}
            lastConnectTime={lastConnectTime}
            alertAllShow={alertAllShow}
            alertContent={alertAllShow}
            autoAtck={autoAtk}
            type={type}
          />
        );
      default:
        return (
          <PopUpCompent
            key="sitePop"
            id={id}
            online={online}
            state={state}
            model={model}
            gps={gps}
            bands={bands}
            version={version}
            intl={intl}
            alias={alias}
            lastConnectTime={lastConnectTime}
            alertAllShow={alertAllShow}
            alertContent={alertAllShow}
            autoAtck={autoAtk}
            type={type}
          />
        );
    }
  };
  // 站点的图标
  private GetSiteGeojson = (map: MapBoxGl.Map | null, fetures: any[]) => {
    map?.addSource("siteSource", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: fetures,
      },
    });

    map?.addLayer({
      id: "siteSource",
      type: "symbol",
      source: "siteSource",
      layout: {
        "icon-image": ["get", "icon"],
        "icon-allow-overlap": true,
      },
    });
    map?.on("mouseenter", "siteSource", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map?.on("mouseleave", "siteSource", () => {
      map.getCanvas().style.cursor = "";
    });
    map?.on("click", "siteSource", e => {
      if ("id" in e) {
        const newFeatures = e.features?.filter(item => {
          return item.properties?.id === e.id;
        });
        const gps: any = newFeatures?.[0]?.geometry;
        gps.coordinates = [e.lngLat.lng, e.lngLat.lat];
        this.addPopup(
          this.getRenderComp(
            newFeatures?.[0]?.properties?.type,
            newFeatures?.[0]?.properties?.id,
            newFeatures?.[0]?.properties?.online,
            newFeatures?.[0]?.properties?.status,
            gps.coordinates,
            e.features?.[0]?.properties?.bands,
            newFeatures?.[0]?.properties?.version,
            this.props.intl,
            newFeatures?.[0]?.properties?.last_connect_time,
            this.props.alertAllShow,
            this.props.alertContent,
            newFeatures?.[0]?.properties?.model,
            newFeatures?.[0]?.properties?.alias,
            e.features?.[0]?.properties?.atuoAttack,
          ),
          e.lngLat.lat,
          e.lngLat.lng,
          map,
        );
      } else {
        const gps: any = e.features?.[0]?.geometry;
        gps.coordinates = [e.lngLat.lng, e.lngLat.lat];

        this.addPopup(
          this.getRenderComp(
            e.features?.[0]?.properties?.type,
            e.features?.[0]?.properties?.id,
            e.features?.[0]?.properties?.online,
            e.features?.[0]?.properties?.status,
            gps.coordinates,
            e.features?.[0]?.properties?.bands,
            e.features?.[0]?.properties?.version,
            this.props.intl,
            e.features?.[0]?.properties?.last_connect_time,
            this.props.alertAllShow,
            this.props.alertContent,
            e.features?.[0]?.properties?.model,
            e.features?.[0]?.properties?.alias,
            e.features?.[0]?.properties?.atuoAttack,
          ),
          e.lngLat.lat,
          e.lngLat.lng,
          map,
        );
      }
    });
    this.addImageSource(this.map as mapboxgl.Map);
    return;
  };

  public handleSelectFeature = (featureId: any) => {
    this.setState({ selectedFeatureId: featureId });
  };
  public getSubstringAfterChar(inputStr: any, char: string) {
    const charIndex = inputStr.indexOf(char);
    if (charIndex !== -1) {
      return inputStr.slice(charIndex + 1);
    } else {
      return null;
    }
  }

  //根据类型，是否在线，是否检测到无人机，来对应不同的站点类型
  private getIcon(type: string, online: boolean, site_id: string) {
    if (type === "CAMERA" && online === true && this.droneIsInSite(site_id)) {
      return "plushDot-camera-marker";
    } else if (type === "CAMERA" && online === true && !this.droneIsInSite(site_id)) {
      return "online-camera-marker";
    } else if (type === "CAMERA" && online === false) {
      return "offline-camera-marker";
    } else if (type === "BASE" && online === true && this.droneIsInSite(site_id)) {
      return "plushDot-siteIcon";
    } else if (type === "BASE" && online === true && !this.droneIsInSite(site_id)) {
      return "online-siteIcon";
    } else if (type === "BASE" && online === false) {
      return "offline-siteIcon";
    } else if (type === "HANDHELD" && online === true && this.droneIsInSite(site_id)) {
      return "plushDot-hand-held";
    } else if (type === "HANDHELD" && online === true && !this.droneIsInSite(site_id)) {
      return "online-hand-held";
    } else if (type === "HANDHELD" && online === false) {
      return "offline-hand-held";
    } else if (type === "UNKNOWN" && online === false) {
      return "offline-siteIcon";
    } else {
      return "online-siteIcon";
    }
  }

  //给地图上添加站点及信息
  private addRadar = (data: SiteInfo[]) => {
    if (!this.styleLoded || data.length === 0) {
      return;
    }
    this.featuers = [];
    this.radarFeatures = [];
    //目前是这里筛选了离线站点
    if (data.length <= 0) return;
    const onlineData = data.filter(item => {
      return item.online === true;
    });
    for (const v of onlineData) {
      const feature = {
        type: "Feature",
        properties: {
          id: v.site_id,
          model: v.model,
          atuoAttack: v.enable_auto_attack,
          version: v.version,
          bands: v.jammerState,
          status: v.state,
          enableDetect: v.enable_detect,
          icon: this.getIcon(v.type, v.online, v.site_id),
          alias: v.alias,
          online: v.online,
          type: v.type,
          range: v.range,
        },
        geometry: {
          type: "Point",
          coordinates: [v.coordinate.longitude, v.coordinate.latitude],
        },
      };
      this.featuers.push(feature);
      //地图蓝色圈
      if (v.range && v.range <= 8000) {
        const radarGeoJson = GetCoordPoint(v.coordinate.longitude, v.coordinate.latitude, v.range);
        const radarFeatur = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [radarGeoJson],
          },
          properties: {},
        };
        v.online && this.radarFeatures.push(radarFeatur);
      }
      if (v.jammerState.some(item => item.state === 2)) {
        const timeFactor = Math.sin(Date.now() * 0.1);
        const radius = 50 + 20 * timeFactor;
        const radarGeoJson = GetCoordPoint(v.coordinate.longitude, v.coordinate.latitude, radius);
        this.siteIds[v.site_id] = {
          sourceId: `radar-source-${v.site_id}`,
          layerId: `radar-layer-${v.site_id}`,
        };

        // 检查源是否已存在
        if (!this.map?.getSource(this.siteIds[v.site_id].sourceId)) {
          this.map?.addImage("pulsing-dot", getPulsingDot(this.map), { pixelRatio: 2 });
          this.map?.addSource(this.siteIds[v.site_id].sourceId, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [radarGeoJson],
              },
              properties: {},
            },
          });

          // 添加图层
          this.map?.addLayer({
            id: this.siteIds[v.site_id].layerId,
            type: "symbol",
            source: this.siteIds[v.site_id].sourceId,
            layout: {
              "icon-image": "pulsing-dot",
            },
          });
        }
      } else {
        if (
          this.siteIds[v.site_id] &&
          this.siteIds[v.site_id].sourceId &&
          this.siteIds[v.site_id].layerId &&
          this.map?.getSource(this.siteIds[v.site_id].sourceId)
        ) {
          this.map?.removeLayer(this.siteIds[v.site_id].layerId);
          this.map?.removeSource(this.siteIds[v.site_id].sourceId);
          delete this.siteIds[v.site_id];
        }
      }

      if (document.getElementById("sitePop")) {
        //update siteInfo站点卡片更新
        const siteSelectHtml = document
          .getElementById("sitePop")
          ?.getElementsByClassName("popCarsClassName")[0]
          ?.getElementsByClassName("ant-card-head")[0]
          ?.getElementsByClassName("ant-card-head-wrapper")[0]
          ?.getElementsByClassName("ant-card-head-title")[0]
          ?.getElementsByClassName("popHeader")[0]
          ?.getElementsByClassName("popHeaderName")[0] as HTMLElement;
        const siteSelect = siteSelectHtml ? siteSelectHtml.title : undefined;
        if (siteSelect === v.site_id || siteSelect === v.alias) {
          const eleUpdate = this.getRenderComp(
            v.type,
            v.site_id,
            v.online,
            v.state,
            [v.coordinate.longitude, v.coordinate.latitude],
            v.jammerState,
            v.version,
            this.props.intl,
            v.last_connect_time,
            this.props.alertAllShow,
            this.props.alertContent,
            v.model,
            v.alias,
            v.enable_auto_attack,
          ) as JSX.Element;
          ReactDOM.render(eleUpdate, document.getElementById("sitePop"));
        }
      }
    }
    this.updateRadarLayer();
    if (this.map?.getLayer("siteSource")) {
      UpdateGetSiteGeojson("siteSource", this.map, this.featuers);
    } else {
      this.GetSiteGeojson(this.map, this.featuers);
    }
  };

  //站点聚合
  private clusterPoints = () => {
    if (!this.map) return;
    if (this.state.pupuObj && Array.isArray(this.state.pupuObj) && this.state.pupuObj.length > 0) {
      this.state.pupuObj.forEach((it: any) => {
        it.remove();
      });
      this.setState({
        pupuObj: [],
      });
    }
    if (this.map.getSource("earthquakes")) {
      this.map.removeLayer("clusters");
      this.map.removeSource("earthquakes");
    }

    const onlineSiteList = this.props.siteList.filter(item => item.online === true);

    let polymerizationPopup: MapBoxGl.Popup | null = null;
    let currentPopup: MapBoxGl.Popup | null = null;
    let popupLogicExecuted = false; // 新增标志位

    if (this.map.getSource("earthquakes")) {
      this.map.removeLayer("clusters");
      this.map.removeSource("earthquakes");
    }

    this.map.addSource("earthquakes", {
      type: "geojson",
      data: this.arrayToGeoJSON(onlineSiteList),
      cluster: true,
      clusterMaxZoom: 26,
      clusterRadius: 8,
    });

    this.map.addLayer({
      id: "clusters",
      type: "circle",
      source: "earthquakes",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": "rgba(81, 187, 214, 0)",
        "circle-radius": ["step", ["get", "point_count"], 20, 5, 30, 20, 40],
      },
    });

    this.map?.on("mouseleave", "clusters", () => {
      if (this.map) {
        this.map.getCanvas().style.cursor = "";
        popupLogicExecuted = false; // 鼠标离开时重置标志位
        this.setState({ hasExecutedPopupLogic: false });
      }
    });
    this.map?.off("mouseenter", "clusters", e => {
      prihandleMouseEnterClusters(e);
    });

    let coordinates: [number, number], matchingSites: any[];
    this.map?.on("mouseenter", "clusters", e => {
      if (this.map) {
        this.map.getCanvas().style.cursor = "pointer";
      }

      if (polymerizationPopup && this.state.isPopupOpen) {
        polymerizationPopup.remove();
        polymerizationPopup = null;
        this.setState({ isPopupOpen: false, polymerizationPopup: null });
      }
      if (!popupLogicExecuted) {
        prihandleMouseEnterClusters(e);
        popupLogicExecuted = true;
      }
    });
    const prihandleMouseEnterClusters = (
      e: MapBoxGl.MapMouseEvent & { features?: MapBoxGl.MapboxGeoJSONFeature[] | undefined } & MapBoxGl.EventData,
    ) => {
      this.state.pupuObj.forEach((it: any) => {
        it.remove();
      });
      this.setState({
        pupuObj: [],
      });
      if (polymerizationPopup) {
        polymerizationPopup.remove();
        polymerizationPopup = null;
        this.setState({ isPopupOpen: false, polymerizationPopup: null });
      }

      e.features?.forEach(item => {
        coordinates = (item.geometry as GeoJSONFeature["geometry"]).coordinates;
        matchingSites = onlineSiteList.filter(
          it =>
            it.coordinate.longitude.toFixed(3) === coordinates[0].toFixed(3) &&
            it.coordinate.latitude.toFixed(3) === coordinates[1].toFixed(3),
        );
      });

      if (this.map && matchingSites.length > 0) {
        if (currentPopup) {
          currentPopup.remove();
          currentPopup = null;
          this.setState({ isPopupOpen: false });
        }

        const popupContent = createPopupContent(matchingSites);
        popupContent.className = "cluserName";
        const tempObj = [...this.state.pupuObj];

        polymerizationPopup = createPopup(coordinates, popupContent);
        polymerizationPopup?.addClassName("cluserAllName");
        tempObj.push(polymerizationPopup);
        this.setState({
          pupuObj: tempObj,
        });
        this.setState({ polymerizationPopup });
        if (polymerizationPopup) {
          polymerizationPopup.addTo(this.map as mapboxgl.Map);
        }
        this.setState({ isPopupOpen: true, hasExecutedPopupLogic: true });
      }
    };

    // 注释：为了简洁起见，删除了重复的代码

    const truncatedValue = (value: string) => (value.length <= 8 ? value : `${value.slice(0, 4)}...${value.slice(-4)}`);

    const createPopupContent = (sites: GeoJSONFeature["properties"][]) => {
      const popupContent = document.createElement("div");
      popupContent.className = "overlapContent";
      popupContent.style.maxHeight = "100px";
      popupContent.style.overflowY = "auto";

      sites.forEach(site => {
        const listItem = document.createElement("div");
        listItem.className = "popup-list-item";
        listItem.innerHTML = `<div style="cursor: pointer;" >
            <strong className="overlaAlias">${this.props.intl.formatMessage({ id: "SiteList.SiteName" })}:</strong>
            <span  title=${site.alias ? site.alias : site.site_id}>
              ${site.alias ? truncatedValue(site.alias) : truncatedValue(site.site_id)}
            </span>
          </div>`;

        listItem.addEventListener("click", () => {
          const filterSiteList: SiteInfo[] = this.props.siteList.filter(
            item => item.online === true && (item.alias === site.alias || item.site_id === site.site_id),
          );

          const gps = {
            geometry: {
              type: "Point",
              coordinates: [site.coordinate.longitude, site.coordinate.latitude],
            },
          };

          if (polymerizationPopup && this.state.isPopupOpen) {
            polymerizationPopup.remove();
            this.setState({ isPopupOpen: false, polymerizationPopup: null });
          }

          filterSiteList.forEach(item => {
            if (item.alias === site.alias || item.site_id === site.site_id) {
              this.map &&
                filterSiteList.length > 0 &&
                this.addPopup(
                  this.getRenderComp(
                    item.type,
                    item.site_id,
                    item.online,
                    item.state,
                    gps.geometry.coordinates,
                    item.jammerState,
                    item.version,
                    this.props.intl,
                    item.last_connect_time,
                    this.props.alertAllShow,
                    this.props.alertContent,
                    item.model,
                    item.alias,
                    item.enable_auto_attack,
                  ),
                  gps.geometry.coordinates[1],
                  gps.geometry.coordinates[0],
                  this.map,
                );
            }
          });
        });
        popupContent.appendChild(listItem);
      });
      return popupContent;
    };

    const createPopup = (coordinates: [number, number], content: HTMLElement) => {
      if (!polymerizationPopup) {
        polymerizationPopup = new mapboxgl.Popup().setLngLat(coordinates).setDOMContent(content);
      } else {
        polymerizationPopup.setLngLat(coordinates).setDOMContent(content);
      }
      return polymerizationPopup;
    };
  };

  private updateRadarLayer = () => {
    if (this.map?.getLayer("radarSource-layer") || this.map?.getLayer("radarSource-layer-border")) {
      //update source
      const source: MapBoxGl.AnySourceImpl = this.map?.getSource("radarSource") as MapBoxGl.AnySourceImpl;
      (source as any).setData({
        type: "FeatureCollection",
        features: this.radarFeatures,
      });
    } else {
      // add layer
      try {
        this.map?.addSource("radarSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: this.radarFeatures,
          },
        });
        this.map?.addLayer({
          id: "radarSource-layer",
          type: "fill",
          source: "radarSource",
          layout: {},
          paint: {
            "fill-color": "#9DE945", // 中间颜色
            "fill-opacity": 0.3,
          },
        });
        //蓝圈边框填充色
        this.map?.addLayer({
          id: "radarSource-border",
          type: "line",
          source: "radarSource",
          layout: {},
          paint: {
            "line-color": "#9DE945",
            "line-width": 1,
            "line-opacity": 0.9,
            "line-dasharray": [3, 2],
          },
        });
      } catch (error) {
        console.log("add source err", error);
      }
    }
  };

  private clearPopup = () => {
    const { popup } = this.state;
    if (popup) {
      popup.remove();
      this.setState({ popup: null });
    }
  };

  async componentDidUpdate(data: IProps, prevState: any) {
    this.drawArea();
    this.map && this.addRadar(data.siteList);
    if (data.siteList.length > 0) {
      const onlineDataSiteList = data.siteList.filter(item => item.online === true);
      const onlineSiteList = this.props.siteList.filter(item => item.online === true);
      for (const onlineDataSite of onlineDataSiteList) {
        for (const onlineSite of onlineSiteList) {
          if (onlineDataSite.site_id === onlineSite.site_id) {
            if (
              onlineDataSiteList.length !== onlineSiteList.length ||
              onlineDataSite.alias !== onlineSite.alias ||
              onlineDataSite.coordinate.latitude !== onlineSite.coordinate.latitude ||
              onlineDataSite.coordinate.longitude !== onlineSite.coordinate.longitude
            ) {
              this.clusterPoints();
            }
          }
        }
      }
      for (const onlineDataSite of onlineDataSiteList) {
        for (const onlineSite of onlineSiteList) {
          if (onlineDataSite.site_id === onlineSite.site_id) {
            if (prevState.mapChangeType !== this.state.mapChangeType) {
              setTimeout(() => {
                this.clusterPoints();
              }, 2000);
            }
          }
        }
      }
    }

    //添加地图类型的高亮
    if (document.getElementsByClassName("mapboxgl-style-list").length) {
      if (
        (document.getElementsByClassName("mapboxgl-style-list")[0].children[0] &&
          document.getElementsByClassName("mapboxgl-style-list")[0].children[0].className.includes("active")) ||
        (document.getElementsByClassName("mapboxgl-style-list")[0].children[1] &&
          document.getElementsByClassName("mapboxgl-style-list")[0].children[1].className.includes("active")) ||
        (document.getElementsByClassName("mapboxgl-style-list")[0].children[2] &&
          document.getElementsByClassName("mapboxgl-style-list")[0].children[2].className.includes("active"))
      ) {
        document.getElementsByClassName("mapboxgl-style-list")[0].children[1].classList.remove("defaultActive");
      }
    }
    // this.map && this.addDrone(data.droneList);
    if (this.props.siteList.length > 0 && !this.state.initCenter) {
      const coord = data.siteList[0]?.coordinate;
      if (!coord) {
        return;
      }
      const centerPoint = { lat: coord.latitude, lng: coord.longitude };
      this.map?.setCenter(centerPoint);
      this.setState({ initCenter: true });
    }
    if (this.map && this.props.intl.locale !== data.intl.locale) {
      document.getElementsByClassName("mapboxgl-style-list")[0].children[0].innerHTML = this.props.intl.formatMessage({
        id: "Map.VECTOR",
      });
      document.getElementsByClassName("mapboxgl-style-list")[0].children[1].innerHTML = this.props.intl.formatMessage({
        id: "Map.SATEL",
      });
      this.addTitle();
      if (document.getElementsByClassName("mapboxgl-style-list")[0].children[2]) {
        document.getElementsByClassName("mapboxgl-style-list")[0].children[2].innerHTML = this.props.intl.formatMessage(
          { id: "Map.VECTOR.offline" },
        );
      }
      this.updateMapLan();
    }
  }

  //不同类型对应不同的图标
  // private addImageSource = (map:mapboxgl.Map) => {
  //   //这里是动画的
  //   !map.hasImage("online-siteIcon") && this.map?.loadImage("/icon/onlineBase.png",(error, image) => {
  //     if (error) throw error
  //     this.map?.addImage("online-siteIcon", trendMarkerIcon(map,"online-siteIcon"), { pixelRatio: 2 } )
  //   })
  //   !map.hasImage("offline-siteIcon") && this.map?.loadImage("/icon/offlineBase.png",(error, image) => {
  //     if (error) throw error
  //     this.map?.addImage("offline-siteIcon", trendMarkerIcon(map,"offline-siteIcon"), { pixelRatio: 2 } )
  //   })
  //   !map.hasImage("plushDot-siteIcon") && this.map?.loadImage("/icon/plushDotBase.png",(error, image) => {
  //     if (error) throw error
  //     this.map?.addImage("plushDot-siteIcon", trendMarkerIcon(map,"plushDot-siteIcon"), { pixelRatio: 2 } )
  //   })
  //   !map.hasImage("online-camera-marker") && map.loadImage("/icon/onlineCamera.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("online-camera-marker", trendMarkerIcon(map,"online-camera-siteIcon"), { pixelRatio: 2 });
  //   })
  //   !map.hasImage("offline-camera-marker") && map.loadImage("/icon/offlineCamera.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("offline-camera-marker", trendMarkerIcon(map,"offline-camera-siteIcon"), { pixelRatio: 2 });
  //   })
  //   !map.hasImage("plushDot-camera-marker") && map.loadImage("/icon/plushDot-camera.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("plushDot-camera-marker", trendMarkerIcon(map,"plushDot-camera-siteIcon"), { pixelRatio: 2 });
  //   })
  //   !map.hasImage("online-hand-held") && map.loadImage("/icon/onlineHandHeld.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("online-hand-held", trendMarkerIcon(map,"online-hand-siteIcon"), { pixelRatio: 2 });
  //   })
  //   !map.hasImage("offline-hand-held") && map.loadImage("/icon/offlineHandHeld.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("offline-hand-held", trendMarkerIcon(map,"offline-hand-siteIcon"), { pixelRatio: 2 });
  //   })
  //   !map.hasImage("plushDot-hand-held") && map.loadImage("/icon/plushDotHandHeld.png", function(error, image) {
  //     if (error) throw error;
  //     map.addImage("plushDot-hand-held", trendMarkerIcon(map,"plushDot-hand-siteIcon"), { pixelRatio: 2 });
  //   })

  //   // if(!map?.hasImage("plushDot-siteIcon")){
  //   //       //     requestAnimationFrame(this.render.bind(this));
  //   //   map?.addImage("plushDot-siteIcon", trendMarkerIcon(map,"plushDot-siteIcon"), { pixelRatio: 2 });
  //   // }
  //   // if(!map?.hasImage("plushDot-camera-marker")){

  //   //   map?.addImage("plushDot-camera-marker", trendMarkerIcon(map,"plushDot-camera-marker"), { pixelRatio: 2 });
  //   // }
  //   // if(!map?.hasImage("plushDot-hand-held")){
  //   //   map?.addImage("plushDot-hand-held", trendMarkerIcon(map,"plushDot-hand-held"), { pixelRatio: 2 });
  //   // }
  //   // if (map.isStyleLoaded()) {
  //     // 修改地图上图层的图标透明度
  //     // trendMarkerIcon(map)
  // // }
  // }
  private addImageSource = (map: mapboxgl.Map) => {
    !map.hasImage("online-siteIcon") &&
      this.map?.loadImage("/icon/onlineBase.png", (error, image) => {
        if (error) throw error;
        this.map?.addImage("online-siteIcon", image as HTMLImageElement);
      });
    !map.hasImage("offline-siteIcon") &&
      this.map?.loadImage("/icon/offlineBase.png", (error, image) => {
        if (error) throw error;
        this.map?.addImage("offline-siteIcon", image as HTMLImageElement);
      });
    !map.hasImage("plushDot-siteIcon") &&
      this.map?.loadImage("/icon/plushDotBase.png", (error, image) => {
        if (error) throw error;
        this.map?.addImage("plushDot-siteIcon", image as HTMLImageElement);
      });
    !map.hasImage("online-camera-marker") &&
      map.loadImage("/icon/onlineCamera.png", function (error, image) {
        if (error) throw error;
        map.addImage("online-camera-marker", image as HTMLImageElement);
      });
    !map.hasImage("offline-camera-marker") &&
      map.loadImage("/icon/offlineCamera.png", function (error, image) {
        if (error) throw error;
        map.addImage("offline-camera-marker", image as HTMLImageElement);
      });
    !map.hasImage("plushDot-camera-marker") &&
      map.loadImage("/icon/plushDotCmera.png", function (error, image) {
        if (error) throw error;
        map.addImage("plushDot-camera-marker", image as HTMLImageElement);
      });
    !map.hasImage("online-hand-held") &&
      map.loadImage("/icon/onlineHandHeld.png", function (error, image) {
        if (error) throw error;
        map.addImage("online-hand-held", image as HTMLImageElement);
      });
    !map.hasImage("offline-hand-held") &&
      map.loadImage("/icon/offlineHandHeld.png", function (error, image) {
        if (error) throw error;
        map.addImage("offline-hand-held", image as HTMLImageElement);
      });
    !map.hasImage("plushDot-hand-held") &&
      map.loadImage("/icon/plushDotHandHeld.png", function (error, image) {
        if (error) throw error;
        map.addImage("plushDot-hand-held", image as HTMLImageElement);
      });
  };

  //让所有站点显示
  private setViewCenter = () => {
    const header: any = {
      "Content-Type": "multipart/form-data",
      token: getToken(),
    };
    request("get", "range", "", header).then(res => {
      interface dataType {
        max: {
          longitude: number;
          latitude: number;
        };
        min: {
          longitude: number;
          latitude: number;
        };
      }
      interface reqResponse {
        code: number;
        data: dataType;
      }
      const rep: reqResponse = res.data as reqResponse;
      try {
        if (rep.code === 0) {
          const v1 = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(rep.data.min.longitude, rep.data.min.latitude < -90 ? -90 : rep.data.min.latitude),
            new mapboxgl.LngLat(rep.data.max.longitude, rep.data.max.latitude > 90 ? 90 : rep.data.max.latitude + 0.03),
          );
          this.map && this.map.fitBounds(v1, { padding: { top: 100, bottom: 100, left: 1, right: 100 } });
        } else {
          console.log("----------get failed");
        }
      } catch (error) {
        console.log("setView err", error);
      }
    });
  };

  private arrayToGeoJSON(inputArray: any[]): GeoJSONFeatureCollection {
    // 创建一个空的 GeoJSON 对象 Feature<Geometry, GeoJsonProperties>
    const geojson: GeoJSONFeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };

    // 循环遍历输入数组中的每个对象
    for (let i = 0; i < inputArray.length; i++) {
      const item = inputArray[i];
      // 创建一个 GeoJSON 特征对象
      const feature: GeoJSONFeature = {
        type: "Feature",
        properties: item,
        geometry: {
          type: "Point",
          coordinates: [item.coordinate.longitude, item.coordinate.latitude],
          alias: item.alias,
        },
      };
      geojson.features.push(feature);
    }
    return geojson;
  }

  private updateMapLan = () => {
    if (this.state.mapType === "mapbox") {
      setTimeout(() => {
        this.map?.setStyle(this.languageCtl.setLanguage(this.map.getStyle(), this.getMapLan() as string));
      }, 500);
    } else {
      setTimeout(() => {
        const style = this.map?.getStyle();
        this.map?.getSource("satellite") &&
          (this.map?.getSource("satellite") as any).setTiles(
            transformStyleJson(style, this.props.intl.locale, this.state.mapType, "satellite"),
          );
        this.map?.getSource("annotion") &&
          (this.map?.getSource("annotion") as any).setTiles(
            transformStyleJson(style, this.props.intl.locale, this.state.mapType, "annotion"),
          );
        this.map?.getSource("vector") &&
          (this.map?.getSource("vector") as any).setTiles(
            transformStyleJson(style, this.props.intl.locale, this.state.mapType, "vector"),
          );
      }, 500);
    }
  };

  private addMapSwitch = () => {
    const header: any = {
      "Content-Type": "multipart/form-data",
      token: getToken(),
    };
    interface dataType {
      value: string;
      key: string;
    }

    interface reqResponse {
      code: number;
      data: dataType[];
    }
    request("get", "config", "", header, { key: "mapbox_url" })
      .then(res => {
        const rep: reqResponse = res.data as reqResponse;
        if (rep.code === 0) {
          this.setState({ offlineMapUrl: rep.data[0].value });
          this.addControl(rep.data[0].value);
          //添加默认选中样式
          document.getElementsByClassName("mapboxgl-style-list")[0].children[1].setAttribute("class", "defaultActive");
        } else {
          this.addControl();
        }
      })
      .catch(err => {
        this.addControl();
        console.log(err);
      });
  };

  private addControl = (supportOfflineUri?: string) => {
    const options: MapboxStyleSwitcherOptions = {
      defaultStyle: "Dark",
      eventListeners: {
        onOpen: (event: MouseEvent) => {
          this.rulerControl.measuringOff();
          return false;
        },
        onSelect: (event: MouseEvent) => {
          return false;
        },
        onChange: (event: MouseEvent, style: string) => {
          this.setState({ mapChangeType: !this.state.mapChangeType });
          return true;
        },
      },
    };
    if (supportOfflineUri) {
      const styles: MapboxStyleDefinition[] = [
        {
          title: `${this.props.intl.formatMessage({ id: "Map.VECTOR" })}`,
          uri: getStaticUrl(this.state.mapType, this.state.vectorMapUrl),
        },
        {
          title: `${this.props.intl.formatMessage({ id: "Map.SATEL" })}`,
          uri: getStaticUrl(this.state.mapType, this.state.sateMapUrl),
        },
        {
          title: `${this.props.intl.formatMessage({ id: "Map.VECTOR.offline" })}`,
          uri: this.state.offlineMapUrl,
        },
      ];

      //地图切换
      this.map?.addControl(new MapboxStyleSwitcherControl(styles, options), "bottom-right");
    } else {
      const styles: MapboxStyleDefinition[] = [
        {
          title: `${this.props.intl.formatMessage({ id: "Map.VECTOR" })}`,
          uri: getStaticUrl(this.state.mapType, this.state.vectorMapUrl),
        },
        {
          title: `${this.props.intl.formatMessage({ id: "Map.SATEL" })}`,
          uri: getStaticUrl(this.state.mapType, this.state.sateMapUrl),
        },
      ];
      //地图切换
      this.map?.addControl(new MapboxStyleSwitcherControl(styles, options), "bottom-right");
    }
  };
  private addTitle = () => {
    document
      .getElementsByClassName("mapboxgl-ctrl-icon")[0]
      .setAttribute("title", this.props.intl.formatMessage({ id: "Map.type.select" }));
    document
      .getElementsByClassName("mapbox-compass")[0]
      .setAttribute("title", this.props.intl.formatMessage({ id: "Map.title.compass" }));
    document
      .getElementsByClassName("mapboxgl-style-allShow")[0]
      .setAttribute("title", this.props.intl.formatMessage({ id: "Map.title.reposition" }));
    document
      .getElementsByClassName("mapbox-control-ruler")[0]
      .setAttribute("title", this.props.intl.formatMessage({ id: "Map.title.distance" }));
    document
      .getElementsByClassName("mapbox-zoom")[0]
      .children[0].setAttribute("title", this.props.intl.formatMessage({ id: "Map.title.zoomIn" }));
    document
      .getElementsByClassName("mapbox-zoom")[0]
      .children[1].setAttribute("title", this.props.intl.formatMessage({ id: "Map.title.zoomOut" }));
  };

  drawArea = () => {
    if (this.props.globalSetting.displayMode["map-area"]) {
      if (this.state.mapStyleLoaded) {
        const geoData = this.props.siteList.filter(item => item.airDefenceArea?.length > 0);
        const geojsonPolygonDefence = {
          type: "FeatureCollection",
          features: [] as Array<any>,
        };
        const geojsonPolygonAlarm = {
          type: "FeatureCollection",
          features: [] as Array<any>,
        };
        //预警圈和防御圈
        geoData.forEach(site => {
          site.airDefenceArea.forEach((area, index) => {
            const geoJsonObj = JSON.parse(area["data"]);
            if (
              geoJsonObj.locations[geoJsonObj.locations.length - 1][0] !== geoJsonObj.locations[0][0] ||
              geoJsonObj.locations[geoJsonObj.locations.length - 1][1] !== geoJsonObj.locations[0][1]
            ) {
              geoJsonObj.locations.push(geoJsonObj.locations[0]);
            }
            if (geoJsonObj.distance > 0) {
              geoJsonObj.locations = GetCoordPoint(
                geoJsonObj.locations[0][0],
                geoJsonObj.locations[0][1],
                geoJsonObj.distance,
              );
            }
            const feature = {
              type: "Feature",
              properties: {
                id: index,
                name: "",
              },
              geometry: {
                coordinates: [geoJsonObj.locations],
                type: "Polygon",
              },
            };
            if (geoJsonObj.areatype === 1) {
              geojsonPolygonAlarm.features.push(feature);
            } else {
              geojsonPolygonDefence.features.push(feature);
            }
          });
        });

        const DrawData = [
          {
            sourceId: "defenceArea",
            layerId: "defenceArea-layer",
            geoJson: geojsonPolygonDefence,
            fillColor: "#FF4A4A",
            zIndex: 2,
          },
          {
            sourceId: "alarmArea",
            layerId: "alarmArea-layer",
            geoJson: geojsonPolygonAlarm,
            fillColor: "#0085FF",
            zIndex: 1,
          },
        ];

        DrawData.forEach(item => {
          if (!this.map?.getSource(item.sourceId)) {
            this.map?.addSource(item.sourceId, { type: "geojson", data: item.geoJson as any });
          } else {
            //update source
            const source: MapBoxGl.AnySourceImpl = this.map?.getSource(item.sourceId) as MapBoxGl.AnySourceImpl;
            (source as any).setData(item.geoJson);
          }
          if (!this.map?.getLayer(item.layerId)) {
            //内部填充色
            this.map?.addLayer({
              id: item.layerId,
              type: "fill",
              source: item.sourceId, // reference the data source
              layout: {},
              paint: {
                "fill-color": item.fillColor, // blue color fill
                "fill-opacity": 0.2,
              },
            });
            this.map?.addLayer({
              id: `${item.layerId}-border`,
              type: "line",
              source: item.sourceId,
              layout: {},
              paint: {
                "line-color": item.fillColor,
                "line-width": 1,
              },
            });
          }
          this.map?.moveLayer("alarmArea-layer", "defenceArea-layer");
          this.map?.moveLayer("defenceArea-layerr", "radarSource-layer");
        });
      }
    } else {
      const DrawData = [
        { sourceId: "defenceArea", layerId: "defenceArea-layer" },
        { sourceId: "alarmArea", layerId: "alarmArea-layer" },
      ];
      DrawData.forEach(item => {
        this.map?.getSource(item.sourceId) && this.map?.removeSource(item.sourceId);
        this.map?.getLayer(item.layerId) && this.map?.removeLayer(item.layerId);
      });
    }
  };
  async componentDidMount() {
    try {
      await Promise.all([this.getAccessToken(), this.getMapType()]);
    } catch (error) {
      console.log("get token or get mapType err", error);
    }
    this.map = new MapBoxGl.Map({
      container: "mapContainer",
      zoom: 12,
      pitch: 0,
      bearing: 0,
      center: { lat: this.props.mapCenter.latitude, lng: this.props.mapCenter.longitude },
      style: getStaticUrl(this.state.mapType, this.state.sateMapUrl),
      maxZoom: 17,
      // projection:"mercator" as unknown as Projection
    });

    if (this.state.mapType === "yandex") {
      (this.map as any)._requestManager.canonicalizeTileset = (tileJSON: any, sourceURL?: string): Array<string> => {
        const canonical = [];
        for (const url of tileJSON.tiles || []) {
          const part = url.split("/");
          part[part.length - 1] = transformToMapLan(this.props.intl.locale, this.state.mapType);
          const modifiedUrl = part.join("/");
          canonical.push(buildApiUrl(modifiedUrl));
        }
        return canonical;
      };
    } else if (this.state.mapType === "google") {
      (this.map as any)._requestManager.canonicalizeTileset = (tileJSON: any, sourceURL?: string): Array<string> => {
        const canonical = [];
        for (const url of tileJSON.tiles || []) {
          const reg = /(?<=hl=).*?(?=&)/;
          const reg1 = /(?<=!3m17!2s).*?(?=!3s)/;
          const reg2 = /(?<=!3s).*?(?=!5e18)/;
          const modifiedUrl = url
            .replace(reg, transformToMapLan(this.props.intl.locale, this.state.mapType))
            .replace(reg1, transformToMapLan(this.props.intl.locale, this.state.mapType))
            .replace(reg2, transformToMapLan(this.props.intl.locale, this.state.mapType));
          canonical.push(modifiedUrl);
        }
        return canonical;
      };
    }

    this.props.initMapInfo(this.map);
    globalMap.setMap(this.map);
    this.map.on("load", () => {
      this.setState({ isMapLoaded: true });
      this.setState({ mapStyleLoaded: true });
      this.clusterPoints();
      //添加地图图片资源
      this.addImageSource(this.map as mapboxgl.Map);
      this.setViewCenter();
    });
    this.map?.on("style.load", () => {
      this.clearPopup();
      this.updateMapLan();
      this.styleLoded = true;
      this.setState({});
      this.addTitle();
    });

    this.map?.on("error", err => {
      console.log("mapbox err occ", err);
      this.getAccessToken();
    });

    this.addMapSwitch();
    //地图在空闲时加载
    this.map?.on("idle", () => {
      this.addImageSource(this.map as mapboxgl.Map);
    });

    //添加控件
    //标尺
    this.map?.addControl(this.rulerControl, "bottom-right");
    //地图复位
    this.map?.addControl(new allShowControl(), "bottom-right");

    //放大缩小
    // this.map?.addControl(new NavigationControl({}), "bottom-right");
    this.map?.addControl(new CompassControl(), "bottom-right");
    this.map?.addControl(new ZoomControl(), "bottom-right");
    this.map?.addControl(this.languageCtl);

    //隐藏maobox标志
    const mapAttrDom = document.getElementsByClassName("mapboxgl-ctrl mapboxgl-ctrl-attrib")[0] as HTMLElement;
    const mapLogoDom = document.getElementsByClassName("mapboxgl-ctrl-bottom-left")[0] as HTMLElement;
    if (mapAttrDom != null) {
      mapAttrDom.style.visibility = "hidden";
    }
    if (mapLogoDom != null) {
      mapLogoDom.style.visibility = "hidden";
    }
    //添加雷达
    this.addRadar(this.props.siteList);
  }

  clearImageSource(map: mapboxgl.Map) {
    // 移除脉动点标记
    map?.removeImage("plushDot-siteIcon");
    map?.removeImage("plushDot-camera-marker");
    map?.removeImage("plushDot-hand-held");
  }
  componentWillUnmount() {
    this.clearImageSource(this.map as mapboxgl.Map);
  }

  render() {
    const dronesWitchTrace = this.props.droneList.filter(
      drone => drone.data[0].tracing?.points && drone.data[0].tracing?.points.length > 0,
    );
    return (
      <div id="mapContainer" className="basemap">
        {/* <canvas id="animationCanvas" style={{ width: "100%", height: "100%" }}></canvas> */}
        {this.map &&
          this.props.droneList.map(drone => (
            <DroneMarkerComponent
              key={drone.drone_id}
              siteCache={this.props.siteCache}
              droneSmallMarkerList={this.props.droneSmallMarkerList}
              map={this.map as mapboxgl.Map}
              drone={drone}
              intl={this.props.intl}
              siteList={this.props.siteList}
              droneLocCache={this.droneLocCache}
              data={this.props.data}
            ></DroneMarkerComponent>
          ))}
        {this.map &&
          this.props.droneTraceShow &&
          dronesWitchTrace.map(drone => (
            // eslint-disable-next-line react/jsx-key
            <TraceComponent
              uuid={drone.drone_id}
              map={this.map as mapboxgl.Map}
              isMaploaded={this.state.isMapLoaded}
              tracePoints={drone.data[0].tracing?.points}
              origin={drone.data[0].tracing?.origin}
              dronePos={drone.data[0].coordinate}
              isStyleLoaded={this.styleLoded}
            ></TraceComponent>
          ))}
      </div>
    );
  }
}

export default Map;
