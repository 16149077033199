import React, { useEffect, useRef } from "react";
import flvjs from "flv.js";
import { useDispatch } from "react-redux";
import { removeVideoUrl } from "../../redux/video/video";

interface FlvPlayerProps {
  url: string;
}

const FlvPlayer: React.FC<FlvPlayerProps> = ({ url }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const dispatch = useDispatch(); // 将 useDispatch 移动到组件作用域内

  const handleUmount = () => {
    dispatch(removeVideoUrl());
  };

  useEffect(() => {
    if (videoRef.current) {
      const flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: url,
      });
      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();
      flvPlayer.play();

      return () => {
        flvPlayer.destroy();
        handleUmount();
      };
    }
  }, [url]);

  return (
    <div>
      <video ref={videoRef} controls style={{ width: "97%", height: "100%" }} muted></video>
    </div>
  );
};

export default FlvPlayer;
