import mapboxgl from "mapbox-gl";
import React, { FC, useEffect, useRef, useState } from "react";
import { colorScheme, pointToGeoLocation } from "../../utils/util";

interface Corrdinte {
  latitude: number;
  longitude: number;
}
interface IProps {
  // Props definition here
  dronePos: Corrdinte | undefined;
  uuid: string;
  origin: Corrdinte | undefined;
  tracePoints: { x: number; y: number }[] | undefined;
  map: mapboxgl.Map;
  isMaploaded: boolean;
  isStyleLoaded: boolean;
}

const TraceComponent: FC<IProps> = props => {
  const [flightMarker, setFligthMarer] = useState<mapboxgl.Marker | null>(null);
  // 保存 state 的引用
  const stateRef = useRef<mapboxgl.Marker | null>(null);

  const getTraceLayerId = () => {
    return props.uuid + "trace";
  };

  const getPoints = () => {
    if (props.tracePoints && props.tracePoints?.length > 0) {
      const tracePoints: number[][] = [];
      props.tracePoints?.forEach(point => {
        const result = pointToGeoLocation(props.origin as Corrdinte, [point.x, point.y]);
        tracePoints.push([result.longitude, result.latitude]);
      });
      if ((props.dronePos?.longitude as number) > 0 && (props.dronePos?.latitude as number) > 0) {
        tracePoints.push([props.dronePos?.longitude as number, props.dronePos?.latitude as number]);
      }
      return tracePoints;
    } else {
      return [];
    }
    // console.log(" get tracepints",tracePoints)
  };

  const drawAndUpdateLine = () => {
    const geojson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: getPoints(),
      },
    };

    if (props.map.getSource(getTraceLayerId())) {
      (props.map.getSource(getTraceLayerId()) as mapboxgl.GeoJSONSource).setData(
        geojson as GeoJSON.Feature<GeoJSON.Geometry>,
      );
    } else {
      props.map.addSource(getTraceLayerId(), {
        type: "geojson",
        data: geojson as GeoJSON.Feature<GeoJSON.Geometry>,
      });

      props.map.addLayer({
        id: getTraceLayerId(),
        type: "line",
        source: getTraceLayerId(),
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": colorScheme.getColorById("1234987", false),
          "line-dasharray": [1, 3], // 虚线样式
          "line-width": 3,
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      // 这是组件卸载时执行的代码
      stateRef.current && stateRef.current.remove();
      props.map.getLayer(getTraceLayerId()) && props.map.removeLayer(getTraceLayerId());
      props.map.getSource(getTraceLayerId()) && props.map.removeSource(getTraceLayerId());
    };
  }, []);

  useEffect(() => {
    try {
      if (props.isMaploaded && props.isStyleLoaded && getPoints().length > 0) {
        if (flightMarker) {
          flightMarker?.setLngLat([getPoints()[0][0] as number, getPoints()[0][1] as number]);
        } else {
          // 创建一个自定义标记的 HTML 元素
          const customMarker = document.createElement("div");
          customMarker.className = "custom-marker"; // 自定义 CSS 类名
          customMarker.style.backgroundImage = 'url("/icon/pilot1.png")'; // 设置背景图像
          // customMarker.style.backgroundImage=""
          // customMarker.style.backgroundColor = "white"
          customMarker.style.backgroundSize = "contain";
          customMarker.style.backgroundRepeat = "no-repect";
          customMarker.style.width = "48px";
          customMarker.style.height = "48px";

          // 创建标记对象，并将自定义 HTML 元素添加到标记中
          const marker = new mapboxgl.Marker({
            element: customMarker,
            anchor: "center",
          })
            //这里的值是-90 90
            .setLngLat([props.origin?.longitude as number, props.origin?.latitude as number]) // 设置标记的经纬度坐标
            .addTo(props.map); // 将标记添加到地图

          setFligthMarer(marker);
          stateRef.current = marker;
          drawAndUpdateLine();
        }
        drawAndUpdateLine();
      }
    } catch (error) {
      console.log("tracing add error", error);
    }
    return () => {
      if (getPoints().length === 0) {
        flightMarker && flightMarker.remove();
      }
    };
  }, [props.origin, props.tracePoints, props.dronePos]);

  // Component logic here
  return <div className="ComponentName"></div>;
};

export default TraceComponent;
