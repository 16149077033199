/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import globalWS from "../../globle";
import { message } from "antd";
import "./List.scss";
import { Check, Clear } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "#141E28",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const bandList = ["443MHz", "900MHz", "1.2GHz", "1.4GHz", "1.5GHz", "1.8GHz", "2.4GHz", "5.8GHz"];

enum bandName {
  "443MHz" = 1,
  "900MHz",
  "1.2GHz",
  "1.4GHz",
  "1.5GHz",
  "1.8GHz",
  "2.4GHz",
  "5.8GHz",
}

interface IProps {
  type: string;
  open: boolean;
  title: string;
  siteIds: string[];
  onClose: any;
  data: any[];
  wideBandChange: boolean;
  autoChange: boolean;
  intl: any;
}

const getRenderContent = (
  type: string,
  onClose: any,
  siteIds: string[],
  data: any[],
  wideBandChange: boolean,
  intl: any,
  setcloseOrOpen: any,
  statusChange: boolean,
  setStatusChange: any,
) => {
  switch (type) {
    case "0":
      return CheckboxList(siteIds, onClose, data, wideBandChange, intl);
    case "1":
      return IconLabelButtons(siteIds, onClose, type, intl);
    case "2":
      return IconLabelButtons(siteIds, onClose, type, intl, setcloseOrOpen, statusChange, setStatusChange);
    case "3":
      return IconLabelButtons(siteIds, onClose, type, intl);
    default:
  }
};

export default function BasicModal(props: IProps) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const [closeOrOpen, setcloseOrOpen] = React.useState("0");
  const [statusChange, setStatusChange] = React.useState(true);

  React.useEffect(() => {
    if (props.siteIds.length <= 0) {
      message.error(`${props.intl.formatMessage({ id: "SiteList.modal.selectSite" })}`);
    } else if (props.siteIds.length > 0 && props.autoChange && closeOrOpen === "1") {
      message.success(`${props.intl.formatMessage({ id: "SiteList.modal.unattended.onSucess" })}`);
    } else if (props.siteIds.length > 0 && props.autoChange && closeOrOpen === "2") {
      message.success(`${props.intl.formatMessage({ id: "SiteList.modal.unattended.offSucess" })}`);
    }
  }, [statusChange, closeOrOpen, props.autoChange]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        // hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="siteListFooterModal">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color="white">
              {props.title}
            </Typography>
            {getRenderContent(
              props.type,
              props.onClose,
              props.siteIds,
              props.data,
              props.wideBandChange,
              props.intl,
              setcloseOrOpen,
              statusChange,
              setStatusChange,
            )}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

function CheckboxList(siteIds: string[], onClose: any, data: any[], wideBandChange: boolean, intl: any) {
  const [checked, setChecked] = React.useState([""]);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const [offOrOn, setOffOrOn] = React.useState("0");
  const [clickChange, setClickChange] = React.useState(true);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleSelectAllClick = () => {
    setSelectAllChecked(!selectAllChecked);
    if (selectAllChecked) {
      setChecked([""]);
    } else {
      setChecked(bandList);
    }
  };
  React.useEffect(() => {
    if (siteIds.length > 0 && wideBandChange && offOrOn === "1") {
      message.success(`${intl.formatMessage({ id: "SiteList.modal.Broadband.onSucess" })}`);
    } else if (siteIds.length > 0 && wideBandChange && offOrOn === "2") {
      message.success(`${intl.formatMessage({ id: "SiteList.modal.Broadband.offSucess" })}`);
    }
  }, [wideBandChange, offOrOn, clickChange]);

  return (
    <div className="listModal">
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {bandList.map(value => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={value}
              secondaryAction={switchBtn(value, siteIds, setOffOrOn, clickChange, setClickChange, intl)}
              disablePadding
            >
              <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} sx={{ color: "white", fontSize: "12px" }} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Stack spacing={3} direction="row-reverse" justifyContent="space-between">
        <Button
          variant="contained"
          sx={{ fontSize: "12px" }}
          onClick={() => {
            handleWBClick(siteIds, onClose, true, checked);
            setOffOrOn("1");
            setClickChange(!clickChange);
          }}
        >
          {intl.formatMessage({ id: "SiteList.modal.open" })}
        </Button>
        <Button
          variant="contained"
          sx={{ fontSize: "12px" }}
          onClick={() => {
            handleWBClick(siteIds, onClose, false, checked);
            setOffOrOn("2");
            setClickChange(!clickChange);
          }}
        >
          {intl.formatMessage({ id: "SiteList.modal.close" })}
        </Button>
        <Button variant="contained" sx={{ fontSize: "12px" }} onClick={() => handleSelectAllClick()}>
          {intl.formatMessage({ id: "SiteList.Full.frequency.strike" })}
        </Button>
      </Stack>
    </div>
  );
}

function handleWBClick(siteIds: string[], onClose: any, enable: boolean, bandList: string[]) {
  const globalWs = globalWS.getWS();
  const bands = [];
  for (const v of bandList) {
    if (v !== "") {
      bands.push({ band: (bandName as any)[v], enable: enable });
    }
  }
  const bandAttackMsg = {
    code: 101,
    data: {
      command_id: "111111",
      site_ids: siteIds,
      type: "wide_band_attack",
      wide_band_attack: {
        gain: 11,
        direction: 1.1,
        elevation: 1.111,
        bands: bands,
      },
    },
  };
  globalWs?.send(JSON.stringify(bandAttackMsg));
  setTimeout(() => {
    onClose();
  }, 1000);
}

function handleBandItem(siteIds: string[], enable: boolean, band: string) {
  const globalWs = globalWS.getWS();
  const bandAttackMsg = {
    code: 101,
    data: {
      command_id: "111111",
      site_ids: siteIds,
      type: "wide_band_attack",
      context: "siteList",
      wide_band_attack: {
        gain: 11,
        direction: 1.1,
        elevation: 1.111,
        bands: [{ band: (bandName as any)[band], enable: enable }],
      },
    },
  };
  globalWs?.send(JSON.stringify(bandAttackMsg));
}

function switchBtn(
  value: string,
  siteIds: string[],
  setOffOrOn: any,
  clickChange: boolean,
  setClickChange: any,
  intl: any,
) {
  const buttons = [
    <Button
      key="two"
      onClick={() => {
        handleBandItem(siteIds, false, value);
        setOffOrOn("2");
        setClickChange(!clickChange);
      }}
    >
      {intl.formatMessage({ id: "SiteList.modal.close" })}
    </Button>,
    <Button
      key="one"
      onClick={() => {
        handleBandItem(siteIds, true, value);
        setOffOrOn("1");
        setClickChange(!clickChange);
      }}
    >
      {intl.formatMessage({ id: "SiteList.modal.open" })}
    </Button>,
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
          m: 1,
        },
      }}
    >
      <ButtonGroup size="small" aria-label="small button group">
        {buttons}
      </ButtonGroup>
    </Box>
  );
}

//无人值守的接口调用
function handleAutoAtk(siteIds: string[], enable: boolean, handleAutoAtk: any) {
  const globalWs = globalWS.getWS();
  //control many
  const bandAttackMsg = {
    code: 101,
    data: {
      command_id: "111111",
      site_ids: siteIds,
      type: "auto_attack",
      auto_attack: {
        enable: enable,
        enable_wide_band: enable ? handleAutoAtk.includes("A") : false,
        enable_smart_attack: enable ? handleAutoAtk.includes("B") : false,
        enable_gnss_spoofer: enable ? handleAutoAtk.includes("C") : false,
      },
    },
  };
  globalWs?.send(JSON.stringify(bandAttackMsg));
}

function handleSwitchByType(type: string, onClose: any, enable: boolean, siteIds: string[], selectedValues?: any) {
  switch (type) {
    case "1":
      //全频打击
      if (enable) {
        handleWBClick(siteIds, onClose, false, bandList);
        handleWBClick(siteIds, onClose, true, bandList);
      } else {
        handleWBClick(siteIds, onClose, false, bandList);
      }
      return;
    case "2":
      //无人值守
      console.log("306", selectedValues);
      handleAutoAtk(siteIds, enable, selectedValues);
      return;
    case "3":
      //版本升级
      return;
    default:
  }
}

// 无人值守弹窗的内容
function IconLabelButtons(
  siteIds: string[],
  onClose: any,
  type: string,
  intl: any,
  setcloseOrOpen?: any,
  statusChange?: boolean,
  setStatusChange?: any,
) {
  const handleWideAttackClick = (enable: boolean) => {
    const selectedValues = [];
    if (selectedValues.length === 0 && enable) {
      selectedValues.push("A", "B", "C"); // 如果没有选择，默认选中 "A"
    }
    handleSwitchByType("2", onClose, enable, siteIds, selectedValues);
    setcloseOrOpen(enable ? "1" : "2");
    setStatusChange(!statusChange);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <>
      <Stack direction="row" className="unattendedButtonGroup" sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          onClick={() => {
            handleWideAttackClick(true);
          }}
          startIcon={<Check />}
          title={intl.formatMessage({ id: "SiteList.modal.open" })}
          variant="outlined"
        >
          {intl.formatMessage({ id: "SiteList.modal.open" })}
        </Button>
        <Button
          onClick={() => {
            handleWideAttackClick(false);
          }}
          startIcon={<Clear />}
          title={intl.formatMessage({ id: "SiteList.modal.close" })}
          variant="outlined"
        >
          {intl.formatMessage({ id: "SiteList.modal.close" })}
        </Button>
      </Stack>
    </>
  );
}
