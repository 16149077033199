import React from "react";
import "./login.scss";
import "animate.css";
import request from "../../api/request";
import { Radio } from "../../components";
import { setTimeout } from "timers";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import * as Redux from "redux";
import { updateLoginInformation } from "../../redux/loginInformation/loginInformation";
import { Input } from "antd";
import { getToken } from "../../utils/util";

enum LoginState {
  Default,
  Loading,
  Empty,
  Error,
  ReqErr,
  OK,
}
interface informationType {
  localName: string;
  loaclRoles: string[];
}

interface IState {
  loginBtn: number;
  userName: string;
  password: string;
  codeShow: boolean;
  codeUrl: string;
  codeNumber: string;
  codeAllUrl: string;
  remember: boolean;
}

interface LoginResponse {
  code: number;
  msg: string;
  token: string;
  roles: string[];
  data: {
    captcha: string;
  };
}
interface reqResponse {
  code: number;
  msg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Login extends React.Component<any, IState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      loginBtn: LoginState.Default,
      userName: "",
      password: "",
      remember: false,
      codeNumber: "",
      codeShow: false,
      codeUrl: "",
      codeAllUrl: "",
    };
    this.checkLogin();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleNameChange = (e: any) => {
    this.setState({
      userName: e.target.value,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleCodeChange = (e: any) => {
    this.setState({
      codeNumber: e.target.value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handlePwdChange = (e: any) => {
    this.setState({
      password: e.target.value,
    });
  };

  private resetLoginState = () => {
    this.setState({
      loginBtn: LoginState.Default,
    });
  };

  private callBack = (remember: boolean) => {
    this.setState({ remember: remember });
  };

  private login = () => {
    this.setState({
      loginBtn: LoginState.Loading,
    });

    if (this.state.userName === "" || this.state.password === "") {
      this.setState({
        loginBtn: LoginState.Empty,
      });
      return;
    }

    const data = new FormData();
    data.append("name", this.state.userName);
    data.append("password", this.state.password);
    if (this.state.codeShow) {
      data.append("captcha_id", this.state.codeUrl);
      data.append("captcha_digits", this.state.codeNumber);
    }

    request("post", "login", data, { "Content-Type": "multipart/form-data" })
      .then(res => {
        const rep: LoginResponse = res.data as LoginResponse;
        if (rep.code === 1) {
          const header: unknown = {
            "Content-Type": "multipart/form-data",
            token: rep.token,
          };
          const data = new FormData();
          data.append("implicit", "true");
          request("get", "permission-for-user-self", data, header, { implicit: true }).then(res => {
            const req: reqResponse = res.data as reqResponse;
            let systemBoolSet = false;
            let deviceBoolSet = false;
            if (!req.code) {
              if (!req.data.length) {
                localStorage.setItem("systenBool", "0");
                localStorage.setItem("deviceBool", "0");
              } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                req.data.forEach((item: any) => {
                  if (!systemBoolSet && item.includes("/v1/alias")) {
                    localStorage.setItem("systenBool", "1");
                    systemBoolSet = true;
                  } else if (!systemBoolSet) {
                    localStorage.setItem("systenBool", "0");
                  }
                  if (!deviceBoolSet && item.includes("attackCommand")) {
                    localStorage.setItem("deviceBool", "1");
                    deviceBoolSet = true;
                  } else if (!deviceBoolSet) {
                    localStorage.setItem("deviceBool", "0");
                  }
                });
              }
            }
          });
          setTimeout(() => {
            this.setState({
              loginBtn: LoginState.OK,
            });
            setTimeout(() => {
              if (this.state.remember) {
                localStorage.setItem("token", rep.token);
              } else {
                sessionStorage.setItem("token", rep.token);
              }

              // localStorage.setItem('Local','zh_CN');
              localStorage.setItem("localName", this.state.userName);
              localStorage.setItem("loaclRoles", JSON.stringify(rep.roles));
              this.props.loginUpdateInformation({ localName: this.state.userName, loaclRoles: rep.roles });
              this.setState({ codeShow: false, codeUrl: "", codeAllUrl: "" });
              this.props.history.push("/");
            }, 1000);
          }, 1500);
        } else if (rep.code === -130) {
          this.setState({ codeShow: true, codeUrl: rep.data?.captcha, codeAllUrl: rep.data?.captcha + ".png" });
          this.setState({
            loginBtn: LoginState.Error,
          });
        } else if (rep.code === -1) {
          this.setState({ codeShow: false, loginBtn: LoginState.Error });
        } else {
          this.setState({
            loginBtn: LoginState.Error,
          });
        }
      })
      .catch(err => {
        this.setState({
          loginBtn: LoginState.ReqErr,
        });
        console.warn("请求失败", err);
      });
  };

  private noRead = () => {
    const data = new FormData();
    data.append("reload", `${new Date().getTime()}`);
    request(
      "get",
      `captcha/${this.state.codeUrl}.png`,
      data,
      { "Content-Type": "multipart/form-data" },
      { reload: new Date().getTime() },
    ).then(() => {
      this.setState({ codeAllUrl: `${this.state.codeUrl}.png?reload=${new Date().getTime()}` });
    });
  };

  private checkLogin(): void {
    if (getToken() != null) {
      this.props.history.push("/");
    }
  }
  render() {
    let LoginBtn: JSX.Element | null = null;
    const warningStyle: React.CSSProperties = { color: "#efefef", backgroundColor: "#e83f3fbd" };
    switch (this.state.loginBtn) {
      case 0:
        LoginBtn = (
          <div className="login-btn" onClick={this.login}>
            {this.props.intl.formatMessage({ id: "loginPage.password.Entersystem" })}
          </div>
        );
        break;
      case 1:
        LoginBtn = (
          <div className="login-btn">
            <svg className="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        );
        break;
      case 2:
        LoginBtn = (
          <div
            className="login-btn animate__animated animate__headShake animate__fast"
            style={warningStyle}
            onClick={this.login}
          >
            {this.props.intl.formatMessage({ id: "loginPage.password.notEmpty" })}
          </div>
        );
        break;
      case 3:
        LoginBtn = (
          <div
            className="login-btn animate__animated animate__headShake animate__fast"
            style={warningStyle}
            onClick={this.login}
          >
            {this.props.intl.formatMessage({ id: "loginPage.password.AuthenticationFailed" })}
          </div>
        );
        break;
      case 4:
        LoginBtn = (
          <div
            className="login-btn animate__animated animate__headShake animate__fast"
            style={warningStyle}
            onClick={this.login}
          >
            {this.props.intl.formatMessage({ id: "loginPage.password.requestFailure" })}
          </div>
        );
        break;
      case 5:
        LoginBtn = (
          <div className="login-btn" onClick={this.login}>
            {this.props.intl.formatMessage({ id: "loginPage.password.LoginSucceeded" })}
          </div>
        );
        break;
    }
    const baseURL =
      process.env.NODE_ENV === "development" ? "https://192.168.85.221/v1/" : window.location.origin + "/v1/";
    return (
      <div className="bg">
        <div className="login">
          <div className="login-title">{this.props.configTitle}</div>
          <div className="login-form">
            <div>{this.props.intl.formatMessage({ id: "loginPage.username" })}</div>
            <Input
              size={"large"}
              onChange={this.handleNameChange}
              onFocus={this.resetLoginState}
              className="antdInput"
            />
            <div className="login-label">{this.props.intl.formatMessage({ id: "loginPage.password" })}</div>
            <Input.Password
              onChange={this.handlePwdChange}
              size={"large"}
              onFocus={this.resetLoginState}
              className="antdInput"
            />
            {this.state.codeShow && (
              <div>
                <div className="login-label">{this.props.intl.formatMessage({ id: "loginPage.code" })}</div>
                <div className="codeAll">
                  <div className="codeFather">
                    <img
                      src={baseURL + "captcha/" + this.state.codeAllUrl}
                      alt=""
                      className="codeImg"
                      onClick={this.noRead}
                    />
                  </div>
                  <Input
                    size={"large"}
                    style={{ width: 150 }}
                    onChange={this.handleCodeChange}
                    onFocus={this.resetLoginState}
                  />
                </div>
              </div>
            )}
          </div>
          {LoginBtn}
          <div className="login-marker">
            <Radio onChangeCallBack={this.callBack} />
            <div className="login-marker-text">
              {this.props.intl.formatMessage({ id: "loginPage.password.remember" })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => {
  return {
    loginRoles: state.loginInformationReducer.loaclRoles,
    loginName: state.loginInformationReducer.localName,
    configTitle: state.configTitleReducer.configTitle,
    curDroneInfo: state.curDroneReducer,
  };
};
const mapDispatchToProps = (dispatch: Redux.Dispatch) => ({
  loginUpdateInformation(payload: informationType) {
    dispatch(updateLoginInformation(payload));
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Login as any) as any) as any);
