/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface globalSettingType {
  voice: boolean;
  display: boolean;
  displayMode: { [key: string]: any };
}

const initialState: globalSettingType = {
  voice: false,
  display: true,
  displayMode: {},
};
export const globalSetting = createSlice({
  name: "globalSetting",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    switchVoice: (state: globalSettingType, action: PayloadAction<boolean>) => {
      state.voice = action.payload;
    },
    displaySwitch: (state: globalSettingType, action: PayloadAction<boolean>) => {
      state.display = action.payload;
    },
    displayModeSwitch: (state: globalSettingType, action: PayloadAction<{ [key: string]: any }>) => {
      state.displayMode = action.payload;
    },
  },
});

export const { switchVoice, displaySwitch, displayModeSwitch } = globalSetting.actions;

export default globalSetting.reducer;
