/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Jammer } from "../../types";
import ClickableChips from "./chip";
import globalWS from "../../globle";
import "./List.scss";
import "./mui.css";
import { List, Switch } from "antd";

enum bandName {
  "443MHz" = 1,
  "900MHz",
  "1.2GHz",
  "1.4GHz",
  "1.5GHz",
  "1.8GHz",
  "2.4GHz",
  "5.8GHz",
}

function getBandSwtich(band: number, bandList: Jammer[]) {
  const res = bandList.filter(bandObj => {
    return bandObj.band === band;
  });
  return res[0].state;
}

function CheckboxListSecondary(siteId: string, bands: Jammer[], intl: any, alertAllShow: any, alertContent: any) {
  const bandList: number[] = [];
  bands.forEach(bandObj => {
    if (bandObj.state) {
      bandList.push(bandObj.band);
    }
  });
  bandList.sort((a, b) => {
    return a - b;
  });
  return (
    <div className="allListName">
      <List className="firstLIistame">
        <List.Item>
          <List.Item.Meta
            title={<div className="listTitle">{intl.formatMessage({ id: "Mui.pop.AllBandswitch" })}</div>}
          />
          <div className="listValue">
            <Switch
              className="allSwitch anotherAllSwitch "
              checked={getFullBandState(bands) ? true : false}
              disabled={bandList.length ? false : true}
              onChange={() => {
                fullBandAtk(siteId, !getFullBandState(bands), bands);
                funAllBandAtk(siteId, bands, alertContent, alertAllShow);
              }}
            />
          </div>
        </List.Item>
      </List>
      <List>
        <div className="bandListName">
          {bandList.map(band => {
            return (
              // eslint-disable-next-line react/jsx-key
              <List.Item>
                <List.Item.Meta title={<div className="listTitle">{bandName[band]}</div>} />
                <div className="listValue">
                  <ClickableChips
                    intl={intl}
                    band={band}
                    siteId={siteId}
                    enable={getBandSwtich(band, bands) === 2}
                  ></ClickableChips>
                </div>
              </List.Item>
            );
          })}
        </div>
      </List>
    </div>
  );
}

enum siteState {
  "Mui.pop.leisure" = 0,
  "Mui.pop.detecting",
  "Mui.pop.breakdown",
}

interface Props {
  id: string;
  online: boolean;
  state: number;
  gps: [number, number];
  model?: string;
  isMaster?: boolean; //是否开启组网
  childTotal?: number;
  childCurCount?: number;
  version?: string;
  bands: Jammer[];
  autoAtck?: boolean;
  enableDetected?: boolean;
  intl: any;
  type: string;
}

function autoAtk(siteId: string, enable: boolean, enable_wide_band?: boolean) {
  const ws = globalWS.getWS();
  const bandAttackMsg = {
    code: 101,
    data: {
      command_id: "111111",
      site_ids: [siteId],
      type: "auto_attack",
      auto_attack: {
        enable: enable,
        enable_wide_band: enable_wide_band ? enable_wide_band : true,
      },
    },
  };
  ws?.send(JSON.stringify(bandAttackMsg));
}

function closeBands(siteId: string, bands: Jammer[]) {
  const ws = globalWS.getWS();
  const bandAttackMsg = {
    code: 101,
    data: {
      site_ids: [siteId],
      type: "wide_band_attack",
      context: "modal",
      wide_band_attack: {
        gain: 11,
        direction: 1.1,
        elevation: 1.111,
        bands: bands.map(item => {
          return { band: item.band, enable: false };
        }),
      },
    },
  };
  ws?.send(JSON.stringify(bandAttackMsg));
}

function openBands(siteId: string, bands: Jammer[]) {
  const ws = globalWS.getWS();
  const bandAttackMsg = {
    code: 101,
    data: {
      command_id: "12121",
      site_ids: [siteId],
      type: "wide_band_attack",
      context: "modal",
      wide_band_attack: {
        gain: 11,
        direction: 1.1,
        elevation: 1.111,
        bands: bands.map(item => {
          return { band: item.band, enable: true };
        }),
      },
    },
  };
  ws?.send(JSON.stringify(bandAttackMsg));
}

function fullBandAtk(siteId: string, enable: boolean, bands: Jammer[]) {
  if (enable) {
    openBands(siteId, bands);
  } else {
    closeBands(siteId, bands);
  }
}

function getFullBandState(bands: Jammer[]) {
  return bands.filter(item => item.state).every(item => item.state === 2);
}

function funAllBandAtk(siteId: string, bands: Jammer[], alertContent: any, alertAllShow: any) {
  setTimeout(() => {
    const allfun = bands.filter(item => item.state).every(item => item.state === 2);
    alertContent(allfun);
    alertAllShow(true);
    setTimeout(() => {
      alertAllShow(false);
    }, 3000);
  }, 3000);
}

function AlignItemsList(props: Props) {
  return (
    <List className="allListName">
      <List.Item>
        <List.Item.Meta
          title={
            <div className="listTitle" title={props.intl.formatMessage({ id: "Mui.pop.currentState" })}>
              {props.intl.formatMessage({ id: "Mui.pop.currentState" })}
            </div>
          }
        />
        <div className="listValue">
          {props.online
            ? props.intl.formatMessage({ id: siteState[props.state === undefined ? "0" : props.state] })
            : props.intl.formatMessage({ id: "homePage.siteOverview.offline" })}
        </div>
      </List.Item>
      <List.Item>
        <List.Item.Meta
          title={
            <div className="listTitle" title="GPS">
              GPS
            </div>
          }
        />
        <div className="listValue">{props.gps[0].toFixed(4) + "," + props.gps[1].toFixed(4)}</div>
      </List.Item>
      {(props.type === "BASE" || props.type === "UNKNOWN") && (
        <List.Item>
          <List.Item.Meta
            title={
              <div className="listTitle" title={props.intl.formatMessage({ id: "Mui.pop.unattended" })}>
                {props.intl.formatMessage({ id: "Mui.pop.unattended" })}
              </div>
            }
          />
          <div className="listValue">
            {localStorage.getItem("deviceBool") === "1" ? (
              <Switch
                className="allSwitch"
                checked={props.autoAtck ? true : false}
                onChange={() => {
                  autoAtk(props.id, !props.autoAtck);
                }}
              />
            ) : (
              <Switch className="allSwitch" checked={props.autoAtck ? true : false} disabled />
            )}
          </div>
        </List.Item>
      )}
      {/* <List.Item>
        <List.Item.Meta
          title={
            <div className="listTitle" title={props.intl.formatMessage({ id: "Mui.pop.equipmentType" })}>
              {props.intl.formatMessage({ id: "Mui.pop.equipmentType" })}
            </div>
          }
        />
        <div className="listValue">{props.model}</div>
      </List.Item> */}
      <List.Item>
        <List.Item.Meta
          title={
            <div className="listTitle" title={props.intl.formatMessage({ id: "Mui.pop.versionInformation" })}>
              {props.intl.formatMessage({ id: "Mui.pop.versionInformation" })}
            </div>
          }
        />
        <div className="listValue">{props.version}</div>
      </List.Item>
    </List>
  );
}

export { CheckboxListSecondary, AlignItemsList };
